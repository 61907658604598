import React, { Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "Component";
const NotFound = lazy(() => import("Component/notFound"));
const mobileRegex = /^\d+$/;
const ValidateParamsWrapper = (props) => {
  const { mobNumber } = useParams();
  const isValid =
    mobNumber !== "" && mobNumber.length === 10 && mobNumber.match(mobileRegex);
  if (isValid) return props.children;
  else
    return (
      <Suspense fallback={<Loader label="Loading..." />}>
        <NotFound />
      </Suspense>
    );
};

export default ValidateParamsWrapper;
