import { getPathData } from "Utils/helpers";
import { FETCH_META, PARTNER_CODE } from "HttpRequest/url";
import { Request } from "HttpRequest";
import { setFavicon } from "./helper";
export const fetchMetaData = ({ setIsLoading }) => {
  Request({
    url: FETCH_META,
    defaultHeaders: false,
    auth: false,
    headers: {
      "partner-code": PARTNER_CODE,
      "Content-Type": "application/json",
    },
    successAction: (resp) => {
      const status = getPathData(resp, ["data", "code"], null);
      if (status === 2000) {
        const metaName = getPathData(resp, ["data", "data", "name"], "");
        const metaLogo = getPathData(resp, ["data", "data", "logo"], "");
        const meatHeading = getPathData(resp, ["data", "data", "heading"], "");
        const metaCode = getPathData(resp, ["data", "data", "code"], "");
        const favicon = getPathData(resp, ["data", "data", "favicon"], "");
        localStorage.setItem("heading", meatHeading);
        localStorage.setItem("name", metaName);
        localStorage.setItem("logo", metaLogo);
        localStorage.setItem("favIcon", favicon);
        localStorage.setItem("code", metaCode);
        document.title = meatHeading;
        setFavicon(favicon);
        setIsLoading && setIsLoading((prev) => ({ ...prev, loader: false }));
      } else {
        // window.location.replace(REDIRECT_URL);
      }
    },
    failureAction: () => {
      // window.location.replace(REDIRECT_URL);
    },
  });
};
