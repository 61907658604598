import React from "react";
const ReportIcon = (props) => {
  const { color = "#29242D" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 18.359C16.6478 18.359 17.1987 18.1321 17.6525 17.6782C18.1063 17.2244 18.3333 16.6735 18.3333 16.0257C18.3333 15.3778 18.1063 14.827 17.6525 14.3731C17.1987 13.9193 16.6478 13.6924 16 13.6924C15.3521 13.6924 14.8013 13.9193 14.3474 14.3731C13.8936 14.827 13.6667 15.3778 13.6667 16.0257C13.6667 16.6735 13.8936 17.2244 14.3474 17.6782C14.8013 18.1321 15.3521 18.359 16 18.359ZM11 23.282H20.9999V22.7975C20.9999 22.324 20.8726 21.9034 20.6179 21.5359C20.3632 21.1684 20.0145 20.8864 19.5718 20.6898C19.0196 20.4539 18.4465 20.2714 17.8525 20.1423C17.2585 20.0133 16.641 19.9487 16 19.9487C15.3589 19.9487 14.7414 20.0133 14.1474 20.1423C13.5534 20.2714 12.9803 20.4539 12.4282 20.6898C11.9855 20.8864 11.6367 21.1684 11.382 21.5359C11.1273 21.9034 11 22.324 11 22.7975V23.282ZM23.5897 28.6667H8.41027C7.73676 28.6667 7.16667 28.4334 6.7 27.9667C6.23333 27.5 6 26.9299 6 26.2564V5.74367C6 5.07016 6.23333 4.50007 6.7 4.0334C7.16667 3.56674 7.73676 3.3334 8.41027 3.3334H17.3871C17.7085 3.3334 18.0175 3.39581 18.314 3.52061C18.6106 3.64538 18.8683 3.81717 19.0871 4.03597L25.2974 10.2462C25.5162 10.465 25.688 10.7227 25.8127 11.0193C25.9375 11.3159 25.9999 11.6249 25.9999 11.9462V26.2564C25.9999 26.9299 25.7666 27.5 25.2999 27.9667C24.8333 28.4334 24.2632 28.6667 23.5897 28.6667ZM23.5897 26.6667C23.6923 26.6667 23.7863 26.624 23.8718 26.5385C23.9572 26.453 24 26.359 24 26.2564V11.8L17.5333 5.33337H8.41027C8.30769 5.33337 8.21366 5.37611 8.12817 5.46157C8.0427 5.54706 7.99997 5.6411 7.99997 5.74367V26.2564C7.99997 26.359 8.0427 26.453 8.12817 26.5385C8.21366 26.624 8.30769 26.6667 8.41027 26.6667H23.5897Z"
        fill={color}
        {...props}
      />
    </svg>
  );
};

export default ReportIcon;
