import React, { Suspense, useState } from "react";
import { Flex, Modal, Typography } from "Component";
import { animated, useSpring } from "@react-spring/web";
import { CardDownArrow, CardUpArrow } from "RetailerJordan/Assets/Icons";
import { RetailerJordanColors } from "Global/colors";
import { createUseStyles } from "react-jss";
import Theme from "Global/retailerJordanTheme";
import UITranslator from "Hooks/uiTranslator";
import JordanLoader from "./jordanLoader";
import DeviceObserver from "Hooks/deviceDetect";
import { truncateText } from "Utils/helpers";

const useStyles = createUseStyles({
  subCardContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: Theme.padding,
    paddingRight: Theme.padding,
    borderRadius: Theme.borderRadius,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    position: "relative",
  },
  cardOne: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
    borderRadius: Theme.borderRadius,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    background: RetailerJordanColors.GREY25,
    marginRight: 8,
  },
  cardTwo: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
    borderRadius: Theme.borderRadius,
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    background: RetailerJordanColors.GREY25,
  },
  detailCont: {
    padding: `${Theme.padding}px ${Theme.padding + 4}px`,
    borderBottom: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
    "&:last-child": {
      borderBottom: "none",
    },
    // borderImage:
  },
  searchCont: {
    marginTop: Theme.padding * 3,
    marginBottom: Theme.padding,
  },
  directionChange: {
    direction: "rtl",
  },
  extraLabel: {
    color: RetailerJordanColors.PRIMARY,
    textDecoration: "underline",
    ...Theme.fontSmallBold,
    marginLeft: Theme?.padding,
    cursor: "pointer",
  },
  rightLabel: {
    marginLeft: 0,
    marginRight: Theme?.padding,
  },
  overrideModalHeader: {
    paddingRight: Theme.padding * 2,
  },
  modalContainer: {
    paddingBottom: `0!important`,
    height: "initial",
  },
  overrideModalCont: {
    marginBottom: 0,
  },
});

const CustomerDetailCard = ({ data, testID = "detailsCard" }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState({
    component: null,
    isVisible: false,
    title: "",
  });
  const lang = UITranslator();
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };
  const styles = useSpring({
    height: isOpen ? 35 * data?.length + 3 : 68,
    overflow: "hidden",
  });
  const { isDesktop } = DeviceObserver();

  const openModal = (res) => {
    setShowModal({
      component: res?.modalComponent,
      isVisible: true,
      title: res?.title,
    });
  };

  const closeModal = () => {
    setShowModal({
      component: null,
      isVisible: false,
      title: "",
    });
  };

  return (
    <Flex direction="column" align="start">
      <Flex
        direction="column"
        overrideClass={`${classes.subCardContainer} ${
          lang ? classes.directionChange : undefined
        }`}
        testID={testID}
      >
        <animated.div style={{ ...styles, width: "100%" }}>
          {data.map((item, index) => {
            return (
              <Flex
                key={index}
                align="start"
                justify="between"
                direction="row"
                overrideClass={classes.detailCont}
              >
                <Typography
                  type="fontSmall"
                  color={RetailerJordanColors.GREY600}
                >
                  {isDesktop
                    ? item?.display
                    : item?.display && truncateText(item?.display, 15)}
                </Typography>
                <Typography
                  type="fontSmall"
                  color={RetailerJordanColors.GREY900}
                >
                  {truncateText(item?.value, 18)}
                  {item.extraLabel && (
                    <span
                      className={`${classes.extraLabel} ${
                        lang ? classes.rightLabel : undefined
                      }`}
                      onClick={
                        !item?.renderExtraComponent
                          ? item?.onClickEvent
                          : () => openModal(item?.renderExtraComponent)
                      }
                      data-testid={`extraLabel_${item.display}`}
                    >
                      {item.extraLabel}
                    </span>
                  )}
                </Typography>
              </Flex>
            );
          })}
        </animated.div>
      </Flex>
      {data.length > 2 && (
        <Flex onClick={toggleHandler}>
          {isOpen ? (
            <CardUpArrow
              overrideStyle={{
                position: "relative",
                float: "right",
                left: "50%",
                cursor: "pointer",
                top: -10,
              }}
            />
          ) : (
            <CardDownArrow
              overrideStyle={{
                position: "relative",
                float: "right",
                left: "50%",
                cursor: "pointer",
                top: -10,
              }}
            />
          )}
        </Flex>
      )}
      <Modal
        open={showModal?.isVisible}
        onClose={closeModal}
        buttonHandler={closeModal}
        titleComponent={() => (
          <Typography type="h3">{showModal?.title}</Typography>
        )}
        overrideModalParent={`${lang ? classes.directionChange : ""} ${
          classes.modalContainer
        }`}
        overrideModalHeader={lang ? classes.overrideModalHeader : ""}
        overrideModalCont={classes.overrideModalCont}
        size="small"
      >
        <Suspense fallback={<JordanLoader />}>
          {showModal?.component ? showModal?.component({ closeModal }) : null}
        </Suspense>
      </Modal>
    </Flex>
  );
};

export default CustomerDetailCard;
