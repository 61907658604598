import React from "react";
const DownloadIcon = (props) => {
  const { overrideStyle = {}, overrideClass = "" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={overrideClass}
      style={{ ...overrideStyle }}
    >
      <mask
        id="mask0_1026_14458"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="17"
      >
        <rect x="0.00195312" width="16" height="16.0009" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1026_14458)">
        <path
          d="M8.00194 10.1595C7.92159 10.1595 7.84681 10.1467 7.77759 10.121C7.70835 10.0954 7.64254 10.0514 7.58015 9.98899L5.50709 7.91582C5.41479 7.8235 5.36757 7.70747 5.36544 7.56772C5.36329 7.42798 5.41051 7.30981 5.50709 7.21323C5.60366 7.11665 5.72246 7.06664 5.86349 7.06322C6.00451 7.0598 6.12331 7.10638 6.2199 7.20296L7.50195 8.4851V3.38478C7.50195 3.24289 7.54981 3.12408 7.64554 3.02836C7.74126 2.93263 7.86006 2.88477 8.00194 2.88477C8.14381 2.88477 8.26261 2.93263 8.35834 3.02836C8.45406 3.12408 8.50192 3.24289 8.50192 3.38478V8.4851L9.78397 7.20296C9.87628 7.11066 9.99401 7.06514 10.1372 7.06642C10.2803 7.06771 10.4002 7.11665 10.4968 7.21323C10.5934 7.30981 10.6417 7.42691 10.6417 7.56452C10.6417 7.70212 10.5934 7.81923 10.4968 7.91582L8.42372 9.98899C8.36133 10.0514 8.29552 10.0954 8.22629 10.121C8.15706 10.1467 8.08228 10.1595 8.00194 10.1595ZM4.20709 13.0007C3.87033 13.0007 3.58529 12.884 3.35195 12.6507C3.11862 12.4173 3.00195 12.1323 3.00195 11.7955V10.5006C3.00195 10.3587 3.04982 10.2399 3.14555 10.1441C3.24128 10.0484 3.36008 10.0005 3.50195 10.0005C3.64383 10.0005 3.76263 10.0484 3.85835 10.1441C3.95408 10.2399 4.00194 10.3587 4.00194 10.5006V11.7955C4.00194 11.8468 4.0233 11.8938 4.06604 11.9365C4.10878 11.9793 4.1558 12.0007 4.20709 12.0007H11.7968C11.8481 12.0007 11.8951 11.9793 11.9378 11.9365C11.9806 11.8938 12.0019 11.8468 12.0019 11.7955V10.5006C12.0019 10.3587 12.0498 10.2399 12.1455 10.1441C12.2412 10.0484 12.36 10.0005 12.5019 10.0005C12.6438 10.0005 12.7626 10.0484 12.8583 10.1441C12.9541 10.2399 13.0019 10.3587 13.0019 10.5006V11.7955C13.0019 12.1323 12.8853 12.4173 12.6519 12.6507C12.4186 12.884 12.1335 13.0007 11.7968 13.0007H4.20709Z"
          fill="#5E0BB7"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
