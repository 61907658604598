// @ts-nocheck
import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
import Typography from "./Typography";
import Label from "./label";
import Colors from "Global/colors";
import { getPathData } from "Utils/helpers";
const useStyles = createUseStyles((theme) => ({
  checkboxContainer: {
    margin: `${theme.margin / 2} ${theme.margin / 2}`,
    cursor: (props) => (props.disabled ? "default" : "pointer"),
    userSelect: "none",
  },
  checkSm: {
    height: "16px",
    width: "16px",
    borderRadius: theme?.borderRadius / 2,
    margin: theme.margin,
    padding: 1,
    "&:hover": {
      boxShadow: (props) =>
        props.disabled ? "" : `0 0 3px 3px ${theme?.primaryColorLight}`,
    },
  },

  checkMd: {
    height: "18px",
    width: "18px",
    borderRadius: theme?.borderRadius / 2,
    margin: theme.margin,
    padding: 1,
    "&:hover": {
      boxShadow: (props) =>
        props.disabled ? "" : `0 0 3px 3px ${theme?.primaryColorLight}`,
    },
  },

  checkLg: {
    height: "22px",
    width: "22px",
    borderRadius: theme?.borderRadius / 2,
    margin: theme.margin,
    padding: 1,
    "&:hover": {
      boxShadow: (props) =>
        props.disabled ? "" : `0 0 3px 3px ${Colors.PRIMARY_LIGHT}`,
    },
  },

  unselected: {
    border: `1px solid ${Colors.UNSELECTED}`,
  },

  imgStyle: {
    width: "100%",
    height: "100%",
  },
  selectedCheckbox: {
    backgroundColor: Colors.PRIMARY,
    borderRadius: theme?.borderRadius / 2,
  },
  disabledCheckbox: {
    backgroundColor: Colors.GREY_40,
  },
}));

export const CheckBox = (props) => {
  const {
    value,
    selected,
    onSelect = null,
    label = "",
    checkboxButtonStyle = {},
    overrideCheckboxContClass = "",
    labelStyle = {},
    color = Colors.PRIMARY,
    // overrideCheckIconClass = "",
    // overrideCheckIconStyle = {},
    overrideCheckContStyle = {},
    overrideCheckContClass = "",
    size = "small",
    disabled = false,
    testID = "checkboxID",
    ...rest
  } = props;
  const classes = useStyles({ ...props, color, selected, size, disabled });
  const {
    checkboxContainer,
    unselected,
    checkSm,
    checkMd,
    checkLg,
    // imgStyle,
    selectedCheckbox,
    disabledCheckbox,
  } = classes;
  const styleObj = {
    size: {
      small: checkSm,
      medium: checkMd,
      large: checkLg,
    },
    fontSize: {
      small: {
        fontSize: 14,
      },
      medium: {
        fontSize: 16,
      },
      large: {
        fontSize: 18,
      },
    },
  };
  const labelSize = getPathData(styleObj, ["fontSize", size], {});
  return (
    <Flex
      overrideClass={` ${checkboxContainer} ${overrideCheckboxContClass}`}
      align="center"
      overrideStyle={{ flex: 1, ...checkboxButtonStyle }}
      onClick={() => {
        if (!disabled) onSelect(value);
      }}
      {...rest}
    >
      <input
        type="checkbox"
        checked={selected}
        disabled={disabled}
        data-testid={testID}
        onChange={() => {
          if (!disabled) onSelect(value);
        }}
        style={{ ...overrideCheckContStyle }}
        className={`${styleObj.size[size]} ${
          !selected ? unselected : selectedCheckbox
        } ${overrideCheckContClass} ${disabled ? disabledCheckbox : ""}`}
      />
      {/* <Flex
        overrideStyle={{ ...overrideCheckContStyle }}
        overrideClass={`${styleObj.size[size]} ${
          !selected ? unselected : selectedCheckbox
        } ${overrideCheckContClass} ${disabled ? disabledCheckbox : ""}`}
        justify="center"
        align="center"
      >
        {selected && (
          <TickIcon
            style={{ ...overrideCheckIconStyle }}
            className={` ${imgStyle}  ${overrideCheckIconClass}`}
          />
        )}
      </Flex> */}
      <Typography
        overrideStyle={{
          color: Colors.LABEL_COLOR,
          ...labelSize,
          ...labelStyle,
        }}
      >
        {label}
      </Typography>
    </Flex>
  );
};
const CheckBoxList = (props) => {
  const {
    options = [],
    handleChange = null,
    error = false,
    errorMsg = "",
    label = "",
    containerStyle = {},
    containerClass = "",
    helpText = "",
    value = [],
    checkboxButtonStyle = {},
    showLabel = true,
    direction = "row",
    color = Colors.PURPLE_10,
    labelStyle = {},
    required = false,
    size = "small",
    labelKey = "label",
    valueKey = "value",
    helpTextColor = Colors.RED,
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useState([...value]);
  useEffect(() => {
    setSelectedValue([...value]);
  }, [props.value]);

  const onChangeHandler = (result = {}) => {
    let updatedValue = getPathData(result, [valueKey], null);
    let updatedNewValue = [...selectedValue, updatedValue];
    if (selectedValue.includes(updatedValue)) {
      const filteredValue = selectedValue.filter((res) => res !== updatedValue);
      setSelectedValue(filteredValue);
      updatedNewValue = [...filteredValue];
    } else {
      setSelectedValue(updatedNewValue);
    }
    handleChange && handleChange(updatedNewValue);
  };
  return (
    <div style={{ width: "100%" }}>
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${containerClass}`}
      >
        {showLabel && <Label label={label} required={required} />}
        <Flex wrap justify="between" align="center" direction={direction}>
          {options.map((res, key) => {
            const val = getPathData(res, [valueKey], null);
            const label = getPathData(res, [labelKey], "");
            const isSelected = selectedValue.includes(val);
            return (
              <CheckBox
                key={key}
                checkboxButtonStyle={checkboxButtonStyle}
                onSelect={onChangeHandler}
                label={label}
                value={res}
                selected={isSelected}
                color={color}
                labelStyle={labelStyle}
                required={required}
                size={size}
                disabled={res?.disabled}
                testID={`checkboxbtn_${key}`}
                {...rest}
              />
            );
          })}
        </Flex>
        {error && <Typography color={Colors.RED}>{errorMsg}</Typography>}
        {helpText && <Typography color={helpTextColor}>{helpText}</Typography>}
      </Flex>
    </div>
  );
};

CheckBox.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  overrideCheckboxContClass: PropTypes.string,
  overrideCheckIconClass: PropTypes.string,
  overrideCheckContClass: PropTypes.string,
  overrideCheckContStyle: PropTypes.object,
  checkboxButtonStyle: PropTypes.object,
  overrideCheckIconStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  testID: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.any,
};
CheckBoxList.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  handleChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  helpText: PropTypes.string,
  name: PropTypes.string,
  containerClass: PropTypes.string,
  checkboxButtonStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  value: PropTypes.array,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  helpTextColor: PropTypes.string,
};

export default CheckBoxList;
