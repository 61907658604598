import { RetailerJordanColors } from "./colors";

export default {
  fontSize: "2 rem",
  padding: 8,
  primaryColor: RetailerJordanColors.PRIMARY,
  primaryColorLight: RetailerJordanColors.PRIMARY_LIGHT,
  primaryColorDark: RetailerJordanColors.PRIMARY_DARK,
  secondaryColor: RetailerJordanColors.SECONDARY,
  black: RetailerJordanColors.BLACK,
  white: RetailerJordanColors.WHITE,
  color: RetailerJordanColors.BLACK,
  backgroundColor: RetailerJordanColors.WHITE,
  borderRadius: 4,
  margin: 8,
  selectbox: {
    options: {
      fontSize: 12,
      fontFamily: "PlusJakartaSans-Regular",
    },
    placeholder: {
      fontSize: 12,
      fontFamily: "PlusJakartaSans-Regular",
    },
    singleValue: {
      fontSize: 12,
      fontFamily: "PlusJakartaSans-Regular",
    },
  },
  typography: {
    p: {
      margin: 2,
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Regular",
    },
    h1: {
      margin: 0,
      fontSize: 24,
      fontFamily: "PlusJakartaSans-Bold",
    },
    h2: {
      margin: 0,
      fontSize: 22,
      fontFamily: "PlusJakartaSans-Medium",
    },
    h3: {
      margin: 0,
      fontSize: 20,
      fontFamily: "PlusJakartaSans-Medium",
    },
    h4: {
      margin: 0,
      fontSize: 18,
      fontFamily: "PlusJakartaSans-Medium",
    },
    h5: {
      margin: 0,
      fontSize: 16,
      fontFamily: "PlusJakartaSans-Medium",
    },
    h6: {
      margin: 0,
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Medium",
    },
    label: {
      fontSize: 15,
      color: RetailerJordanColors.LABEL_COLOR,
      fontFamily: "PlusJakartaSans-Regular",
      letterSpacing: "0.02em",
    },
    link: {
      fontSize: 16,
      color: RetailerJordanColors.LINK,
      fontFamily: "PlusJakartaSans-Regular",
      letterSpacing: "0.02em",
    },
    notify: {
      fontSize: 15,
      color: RetailerJordanColors.BLACK,
      fontFamily: "PlusJakartaSans-Semibold",
      letterSpacing: "0.02em",
    },
    fontBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 24,
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0.48px",
    },
    fontLargeBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.48px",
    },
    fontLargeSemiBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0.48px",
    },
    fontLarge: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.48px",
    },
    fontMediumBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.4px",
    },
    fontMediumSemiBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "0.4px",
    },
    fontMedium: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: "0.28px",
    },
    fontSmallBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.28px",
    },
    fontSmallSemiBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0.28px",
    },
    fontSmall: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.28px",
    },
    fontXSmallBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 12,
      fontWeight: 600,
      fontStyle: "normal",
      letterSpacing: "0.28px",
    },
    fontXSmallSemiBold: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 12,
      fontWeight: 500,
      fontStyle: "normal",
      letterSpacing: "0.28px",
    },
    fontXSmall: {
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.28px",
    },
  },
};
