// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import Theme from "Global/oldRetailerTheme";
const colorCode = {
  primary: Theme.primaryColor,
  secondary: Theme.secondaryColor,
  tertiary: Colors.RED,
};
// @ts-ignore
const useStyles = createUseStyles((theme) => ({
  btnSm: {
    height: "max-content",
    width: "max-content",
    // @ts-ignore
    padding: theme?.padding,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    border: "none",
    boxSizing: "border-box",
  },
  btnMd: {
    width: "max-content",
    height: "max-content",
    // @ts-ignore
    padding: theme?.padding + 2,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    border: "none",
    boxSizing: "border-box",
  },
  btnLg: {
    width: "max-content",
    height: "max-content",
    // @ts-ignore
    padding: theme?.padding + 6,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    border: "none",
    boxSizing: "border-box",
  },

  btnpm: {
    // @ts-ignore
    backgroundColor: theme?.primaryColor,
    color: Colors.WHITE,
  },

  btnsc: {
    // @ts-ignore
    backgroundColor: theme?.secondaryColor,
    color: Colors.WHITE,
  },

  btnter: {
    backgroundColor: Colors.RED,
    color: Colors.WHITE,
  },

  btnfill: {
    backgroundColor: theme?.primaryColor,
    border: `1px solid ${theme?.primaryColor}`,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnout: {
    backgroundColor: "transparent",
    // @ts-ignore
    border: ({ color }) => `1px solid ${colorCode[color]}`,
    // @ts-ignore
    color: ({ color }) => colorCode[color],
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btninfo: {
    backgroundColor: Colors.BLACK,
    border: `1px solid ${Colors.BLACK}`,
    color: Colors.WHITE,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnwarn: {
    backgroundColor: Colors.YELLOW,
    border: `1px solid ${Colors.YELLOW}`,
    color: Colors.BLACK,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btndanger: {
    backgroundColor: Colors.RED,
    border: `1px solid ${Colors.RED}`,
    color: Colors.WHITE,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnlink: {
    backgroundColor: Colors.WHITE,
    border: `1px solid ${Colors.BLACK}`,
    color: Colors.BLACK,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },

  btnerr: {
    backgroundColor: "transparent",
    border: `1px solid ${Colors.RED}`,
    color: Colors.RED,
    // @ts-ignore
    opacity: (props) => (props?.disabled ? 0.3 : 1),
    "&:hover": {
      // @ts-ignore
      opacity: (props) => (props?.disabled ? 0.3 : 0.8),
    },
  },
}));

// @ts-ignore
const Button = (props) => {
  const {
    children,
    type = null,
    onClick = null,
    color = "primary",
    size = "medium",
    btnType = "filled",
    overrideStyle = {},
    disabled = false,
    buttonClass = "",
    testID = "btnId",
    ...extraProps
  } = props;
  const classes = useStyles({ ...props, color, disabled });
  const {
    // @ts-ignore
    btnSm,
    // @ts-ignore
    btnMd,
    // @ts-ignore
    btnLg,
    // @ts-ignore
    btnpm,
    // @ts-ignore
    btnsc,
    // @ts-ignore
    btnter,
    // @ts-ignore
    btnfill,
    // @ts-ignore
    btnout,
    // @ts-ignore
    btninfo,
    // @ts-ignore
    btnwarn,
    // @ts-ignore
    btndanger,
    // @ts-ignore
    btnlink,
    // @ts-ignore
    btnerr,
  } = classes;
  const styledObj = {
    size: {
      small: btnSm,
      medium: btnMd,
      large: btnLg,
    },
    color: {
      primary: btnpm,
      secondary: btnsc,
      tertiary: btnter,
    },
    style: {
      filled: btnfill,
      outline: btnout,
      info: btninfo,
      warning: btnwarn,
      danger: btndanger,
      link: btnlink,
      error: btnerr,
    },
  };
  return (
    <button
      className={`
        ${buttonClass}
        ${
          // @ts-ignore
          styledObj.style[btnType]
        } 
        ${
          // @ts-ignore
          styledObj.size[size]
        }
        ${
          // @ts-ignore
          styledObj.color[color]
        }
        `}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        cursor: "pointer",
        ...overrideStyle,
      }}
      data-testid={testID}
      {...extraProps}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  overrideStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  btnType: PropTypes.oneOf([
    "filled",
    "outline",
    "info",
    "warning",
    "danger",
    "link",
    "error",
  ]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  testID: PropTypes.string,
  type: PropTypes.string,
  buttonClass: PropTypes.string,
};
export default Button;
