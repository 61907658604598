import React from "react";
import Flex from "./flex";
const NotFound = () => {
  return (
    <Flex justify="center" align="center">
      Not Found
    </Flex>
  );
};

export default NotFound;
