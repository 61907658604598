import Colors from "./colors";

export default {
  fontSize: "2 rem",
  padding: 8,
  primaryColor: Colors.PRIMARY,
  primaryColorLight: Colors.PRIMARY_LIGHT,
  primaryColorDark: Colors.PRIMARY_DARK,
  secondaryColor: Colors.SECONDARY,
  black: Colors.BLACK,
  white: Colors.WHITE,
  color: Colors.BLACK,
  backgroundColor: Colors.WHITE,
  borderRadius: 4,
  margin: 8,
  selectbox: {
    options: {
      fontSize: 12,
      fontFamily: "Poppins-Regular",
    },
    placeholder: {
      fontSize: 12,
      fontFamily: "Poppins-Regular",
    },
    singleValue: {
      fontSize: 12,
      fontFamily: "Poppins-Regular",
    },
  },
  typography: {
    p: {
      margin: 2,
      fontSize: 14,
      fontFamily: "Poppins-Light",
    },
    h1: {
      margin: 0,
      fontSize: 24,
      fontFamily: "Poppins-Bold",
    },
    h2: {
      margin: 0,
      fontSize: 22,
      fontFamily: "Poppins-Medium",
    },
    h3: {
      margin: 0,
      fontSize: 20,
      fontFamily: "Poppins-Medium",
    },
    h4: {
      margin: 0,
      fontSize: 18,
      fontFamily: "Poppins-Medium",
    },
    h5: {
      margin: 0,
      fontSize: 16,
      fontFamily: "Poppins-Medium",
    },
    h6: {
      margin: 0,
      fontSize: 14,
      fontFamily: "Poppins-Medium",
    },
    label: {
      fontSize: 15,
      color: Colors.LABEL_COLOR,
      fontFamily: "Poppins-Light",
      letterSpacing: "0.02em",
    },
    link: {
      fontSize: 16,
      color: Colors.LINK,
      fontFamily: "Poppins-Regular",
      letterSpacing: "0.02em",
    },
    notify: {
      fontSize: 15,
      color: Colors.BLACK,
      fontFamily: "OpenSans-Semibold",
      letterSpacing: "0.02em",
    },
  },
};
