export const InitialState = {
  modalState: {
    isVisible: false,
    component: null,
    title: "",
    extraModalProps: {},
  },
  loaderState: {
    isVisible: false,
    title: "",
    extraModalProps: {},
  },
};
