import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Flex from "./flex";
import { createUseStyles } from "react-jss";
import Typography from "./Typography";
import { getPathData } from "Utils/helpers";

const useStyles = createUseStyles((theme) => ({
  singleItemCont: {
    boxSizing: "border-box",
    cursor: "pointer",
    // @ts-ignore
    marginTop: theme.margin,
    boxShadow: "2px rgba(57, 63, 72, 0.3)",
    // @ts-ignore
    borderRadius: theme.borderRadius,
  },
}));

const List = (props) => {
  const {
    options = [],
    overrideContainerClass = "",
    overrideContainerStyle = {},
    listType = "unordered",
    orderedListType = "1",
    overrideSingleComponantClass = "",
    overrideSingleComponantStyle = {},
  } = props;

  const classes = useStyles();
  const { singleItemCont } = classes;
  const [list, setList] = useState([...options]);
  useEffect(() => {
    setList(options);
  }, [props.options]);

  if (listType === "unordered") {
    return (
      <Flex
        direction="column"
        overrideClass={overrideContainerClass}
        overrideStyle={overrideContainerStyle}
      >
        <ul>
          {list.map((item, index) => {
            const customComponent = getPathData(item, ["component"], null);
            return (
              <Flex
                data-testid={`list_${index}`}
                overrideStyle={overrideSingleComponantStyle}
                overrideClass={`${singleItemCont} ${overrideSingleComponantClass}`}
                key={index}
              >
                <li>
                  {customComponent ? (
                    customComponent(item)
                  ) : (
                    <Typography>{item?.label}</Typography>
                  )}
                </li>
              </Flex>
            );
          })}
        </ul>
      </Flex>
    );
  } else {
    return (
      <Flex
        direction="column"
        overrideClass={overrideContainerClass}
        overrideStyle={overrideContainerStyle}
      >
        <ol type={orderedListType}>
          {list.map((item, index) => {
            const customComponent = getPathData(item, ["component"], null);
            return (
              <Flex
                data-testid={`list_${index}`}
                overrideStyle={overrideSingleComponantStyle}
                overrideClass={`${singleItemCont} ${overrideSingleComponantClass}`}
                key={index}
              >
                <li>
                  {customComponent ? (
                    customComponent(item)
                  ) : (
                    <Typography>{item?.label}</Typography>
                  )}
                </li>
              </Flex>
            );
          })}
        </ol>
      </Flex>
    );
  }
};

List.propTypes = {
  overrideStyle: PropTypes.object,
  overrideContainerStyle: PropTypes.object,
  overrideSingleComponantStyle: PropTypes.object,
  listType: PropTypes.oneOf(["unordered", "ordered"]),
  orderedListType: PropTypes.oneOf(["A", "I", "i", "1", "a"]),
  overrideSingleComponantClass: PropTypes.string,
  overrideContainerClass: PropTypes.string,
  options: PropTypes.array,
};

export default List;
