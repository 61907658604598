import React from "react";
import { createUseStyles } from "react-jss";
import { Typography, Flex } from "Component";
import { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";
import { UploadFileIcon } from "RetailerJordan/Assets/Icons";
const useStyles = createUseStyles({
  uploadButton: {
    color: RetailerJordanColors.PRIMARY,
    marginTop: Theme?.margin,
  },
  fileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    minWidth: "70%",
    maxWidth: "100%",
    textAlign: "center",
  },
});
const DocumentsUpload = (props) => {
  const {
    preview = "",
    label = "Click here to upload file",
    subLabel = "JPG or PNG less than 5mb",
    imageStyle = { width: "100%", height: 110, resizeMode: "contain" },
    fileName = "",
  } = props;
  const classes = useStyles();
  return (
    <Flex direction="row" align="center" justify="center">
      {!preview ? (
        <Flex direction="column" align="center" testID="upload_file">
          {" "}
          <UploadFileIcon />
          <Flex
            overrideClass={classes.uploadButton}
            justify="center"
            align="center"
            direction="column"
          >
            <Typography
              type="fontSmallBold"
              color={RetailerJordanColors.GREY900}
              overrideStyle={{
                marginBottom: Theme.margin,
                opacity: 0.8,
                textAlign: "center",
              }}
            >
              {label}
            </Typography>
            <Typography
              type="fontXSmall"
              color={RetailerJordanColors.GREY600}
              overrideStyle={{
                opacity: 0.5,
                textAlign: "center",
                width: "90%",
                wordWrap: "break-word",
              }}
            >
              {subLabel}
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" align="center" testID="file_preview">
          <img src={preview} style={imageStyle} alt={fileName} />
          <Typography
            overrideClass={classes.fileName}
            testID="uploaded_filename"
          >
            {fileName}
          </Typography>
        </Flex>
      )}
      {/* <Typography color={Colors.RED}>{errorMsg}</Typography> */}
    </Flex>
  );
};

export default DocumentsUpload;
