import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Flex from "./flex";
import Colors from "Global/colors";

const useStyles = createUseStyles((theme) => ({
  Tooltip_Wrapper: {
    display: "inline-block",
    position: "relative",
  },

  Tooltip_tip: {
    width: "150%",
    position: "absolute",
    // @ts-ignore
    borderRadius: theme.borderRadius * 2,
    // @ts-ignore
    padding: theme.padding * 1,
    fontSize: "14px",
    fontFamily: "sans-serif",
    zIndex: "100",
    whiteSpace: "nowrap",
    color: Colors.WHITE,
    background: Colors.BLACK,
  },

  direction: {
    "&Top": {
      position: "absolute",
      marginLeft: "60%",
      bottom: "100%",
    },
  },
}));
const Tooltip = (props) => {
  const {
    delay,
    children,
    direction = "Top",
    content = "",
    ...extraProps
  } = props;
  let timeout;
  const [active, setActive] = useState(false);
  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };
  const classes = useStyles({
    ...props,
    direction: "Top" || "Bottom" || "Left" || "Right",
  });
  const { Tooltip_tip, Tooltip_Wrapper } = classes;
  return (
    <div
      className={Tooltip_Wrapper}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      {...extraProps}
    >
      {children}
      {active && (
        <Flex overrideClass={`${Tooltip_tip} ${direction}`}>{content}</Flex>
      )}
    </div>
  );
};

export default Tooltip;
