import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { animated } from "@react-spring/web";
const useStyles = createUseStyles({
  container: {
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    transition: "all 0.3s ease",
    fontFamily: "Poppins-Regular",
  },
  normalContainer: {
    width: "100%",
    boxSizing: "border-box",
    transition: "all 0.3s ease",
  },
});
const fetchValues = {
  between: "space-between",
  around: "space-around",
  center: "center",
  start: "flex-start",
  end: "flex-end",
  evenly: "space-evenly",
  inherit: "inherit",
  initial: "inital",
  stretch: "stretch",
  baseline: "baseline",
};
const Flex = (props) => {
  const {
    children = null,
    wrap = false,
    justify = "",
    direction = "row",
    align = "",
    overrideStyle = {},
    overrideClass = "",
    innerRef = null,
    isFlex = true,
    testID = "flexBoxID",
    ...rest
  } = props;
  const classes = useStyles();
  if (isFlex) {
    return (
      <animated.div
        className={`${classes.container} ${overrideClass}`}
        style={{
          flexDirection: direction,
          justifyContent: fetchValues[justify],
          alignItems: fetchValues[align],
          flexWrap: wrap ? "wrap" : "nowrap",
          ...overrideStyle,
        }}
        ref={innerRef}
        data-testid={testID}
        {...rest}
      >
        {children}
      </animated.div>
    );
  } else {
    return (
      <animated.div
        className={`${classes.normalContainer} ${overrideClass}`}
        style={{
          ...overrideStyle,
        }}
        ref={innerRef}
        data-testid={testID}
        {...rest}
      >
        {children}
      </animated.div>
    );
  }
};
Flex.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  justify: PropTypes.oneOf([
    "start",
    "end",
    "between",
    "around",
    "center",
    "evenly",
    "inherit",
    "initial",
  ]),
  align: PropTypes.oneOf([
    "between",
    "around",
    "center",
    "stretch",
    "baseline",
    "end",
    "start",
  ]),
  direction: PropTypes.oneOf([
    "row",
    "column",
    "row-reverse",
    "column-reverse",
    "initial",
    "inherit",
  ]),
  overrideStyle: PropTypes.object,
  wrap: PropTypes.bool,
  overrideClass: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onDragStart: PropTypes.func,
  draggable: PropTypes.bool,
  onClick: PropTypes.func,
  isFlex: PropTypes.bool,
  testID: PropTypes.string,
  onDragEnter: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragOver: PropTypes.func,
};
export default Flex;
