import { Flex } from "Component";
import { RetailerJordanColors } from "Global/colors";
import UITranslator from "Hooks/uiTranslator";
import React from "react";
import { Range, getTrackBackground } from "react-range";
const JordanSlider = (props) => {
  const {
    minVal = 0,
    maxVal = 0,
    stepCount = 1,
    value = [0],
    onSlide = null,
    testID = "downpaymentRangeSlider",
  } = props;
  const lang = UITranslator();

  const Direction = {
    Right: "to right",
    Left: "to left",
    Down: "to bottom",
    Up: "to top",
  };
  return (
    <Range
      values={value}
      step={stepCount}
      min={minVal}
      max={maxVal}
      direction={lang ? Direction.Left : Direction.Right}
      onChange={(values) => onSlide && onSlide(values)}
      renderTrack={({ props, children }) => (
        <Flex
          onMouseDown={props?.onMouseDown}
          onTouchStart={props?.onTouchStart}
          style={{
            ...props?.overrideTrackStyle,
            height: "36px",
            display: "flex",
            width: "100%",
          }}
          testID="sliderTrack"
        >
          <Flex
            innerRef={props?.ref}
            overrideStyle={{
              height: "8px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values: value,
                colors: [
                  RetailerJordanColors.PURPLE_10,
                  RetailerJordanColors.BRAND75,
                ],
                min: minVal,
                max: maxVal,
                direction: lang ? Direction.Left : Direction.Right,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </Flex>
        </Flex>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            boxSizing: "border-box",
            cursor: "pointer",
            outline: "none",
            height: "24px",
            width: "24px",
            borderRadius: "15px",
            backgroundColor: RetailerJordanColors.WHITE,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 2px 6px #AAA",
          }}
          data-testid="sliderThumb"
        >
          <div
            style={{
              height: "15px",
              width: "15px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: RetailerJordanColors.PURPLE_10,
            }}
          />
        </div>
      )}
      data-testid={testID}
    />
  );
};

export default JordanSlider;
