import React from "react";
const CardUpArrow = (props) => {
  const { overrideStyle = {}, overrideClass = "" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ ...overrideStyle }}
      className={overrideClass}
      data-testid="cardUpArrow_id"
    >
      <path
        d="M12 10.9692L14.5577 13.5269C14.6945 13.6653 14.8656 13.7346 15.0709 13.7346C15.2762 13.7346 15.4532 13.6653 15.6019 13.5269C15.757 13.3782 15.833 13.2 15.8298 12.9923C15.8266 12.7846 15.7506 12.6064 15.6019 12.4577L12.6327 9.48845C12.4519 9.30768 12.241 9.2173 12 9.2173C11.759 9.2173 11.5481 9.30768 11.3673 9.48845L8.38268 12.4731C8.23396 12.6218 8.16057 12.7974 8.1625 13C8.16442 13.2025 8.24294 13.3782 8.39808 13.5269C8.54678 13.6653 8.72241 13.7355 8.92498 13.7375C9.12754 13.7394 9.30317 13.666 9.45188 13.5173L12 10.9692ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5Z"
        fill="#6B6572"
      />
    </svg>
  );
};

export default CardUpArrow;
