import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { getPathData } from "Utils/helpers";
import Flex from "./flex";
import Pagination from "./pagination";
import Colors from "Global/colors";
const useStyles = createUseStyles((theme) => ({
  container: {
    overflow: "auto",
    height: "300px",
    // @ts-ignore
    width: (props) => props?.width,
    border: `1px solid ${Colors.BORDERCOLOR}`,
    // @ts-ignore
    borderRadius: theme.borderRadius,
  },
  tableContainer: {
    borderCollapse: "collapse",
    margin: 0,
    width: "100%",
    tableLayout: "fixed",
    backgroundColor: Colors.WHITE,
  },
  bodyContainer: {},
  labelRowContainer: {
    backgroundColor: Colors.GREY_20,
    color: Colors.BLACK,
    // @ts-ignore
    position: (props) => (props?.headerSticky ? "sticky" : ""),
    top: 0,
  },
  rowValueCont: {
    borderBottom: `0.2px solid ${Colors.BORDERCOLOR}`,
  },
  column: {
    // @ts-ignore
    padding: theme?.padding,
    minWidth: "100px",
    whiteSpace: "wrap",
    textAlign: "left",
  },
  paginationCont: {
    // @ts-ignore
    margin: theme?.margin,
  },
}));
const Table = (props) => {
  const {
    columns = [],
    rows = [],
    maxHeight = "100px",
    width = "100%",
    headerSticky = true,
    paginationEnabled = false,
    totalPages = 0,
    range = 0,
    position = "right",
    overrideContainer = "",
    overrideTableContainer = "",
    overrideLabelRowContainer = "",
    overrideColumn = "",
    overrideHeadColumn = "",
    overrideBodyContainer = "",
    overrideRowValueCont = "",
    overridePaginationStyle = "",
    testID = "tableID",
    onChangePageHandler = null,
    ...rest
  } = props;
  const classes = useStyles({ ...props, maxHeight, width, headerSticky });
  const {
    container,
    tableContainer,
    bodyContainer,
    labelRowContainer,
    column,
    rowValueCont,
    paginationCont,
  } = classes;
  return (
    <Flex direction="column">
      <Flex overrideClass={`${container} ${overrideContainer}`}>
        <table
          className={`${tableContainer} ${overrideTableContainer}`}
          data-testid={testID}
          {...rest}
        >
          <thead>
            <tr
              className={`${labelRowContainer} ${overrideLabelRowContainer}`}
              data-testid={`head_row`}
            >
              {columns.map((res, key) => {
                return (
                  <th
                    key={`head_${key}`}
                    className={`${column} ${overrideHeadColumn}`}
                    data-testid={`head_column_${key}`}
                  >
                    {res?.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={`${bodyContainer} ${overrideBodyContainer}`}>
            {rows.map((res, key) => {
              return (
                <tr
                  key={`row_${key}`}
                  className={`${rowValueCont} ${overrideRowValueCont}`}
                  data-testid={`row_${key}`}
                >
                  {columns.map((result, key1) => {
                    const customComponent = getPathData(
                      result,
                      ["component"],
                      null
                    );
                    return customComponent ? (
                      <td
                        key={`column_${key}_${key1}`}
                        className={`${column} ${overrideColumn}`}
                        data-testid={`column_${key}_${key1}`}
                      >
                        {customComponent(res)}
                      </td>
                    ) : (
                      <td>{result?.label}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Flex>
      {paginationEnabled && (
        <Flex overrideClass={`${paginationCont} ${overridePaginationStyle}`}>
          {/* <Pagination
            totalPages={totalPages}
            position={position}
            maximumCountToDisplay={range}
            onChangeHandler={onChangePageHandler}
          /> */}
          <Pagination
            maximumCountToDisplay={range}
            position={position}
            totalPages={totalPages}
            onChangeHandler={onChangePageHandler}
            pageNumber={1}
          />
        </Flex>
      )}
    </Flex>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  paginationEnabled: PropTypes.bool,
  headerSticky: PropTypes.bool,
  totalPages: PropTypes.number,
  range: PropTypes.number,
  overrideContainer: PropTypes.string,
  overrideTableContainer: PropTypes.string,
  overrideLabelRowContainer: PropTypes.string,
  overrideColumn: PropTypes.string,
  overrideHeadColumn: PropTypes.string,
  overrideBodyContainer: PropTypes.string,
  overrideRowValueCont: PropTypes.string,
  overridePaginationStyle: PropTypes.string,
  testID: PropTypes.string,
  position: PropTypes.oneOf(["right", "left"]),
  onChangePageHandler: PropTypes.func,
};

export default Table;
