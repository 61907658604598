import React from "react";
import { StepBackArrowIcon } from "RetailerJordan/Assets/Icons";
import { Flex } from "Component";

const PreviousNavigator = ({ navigateHandler }) => {
  return (
    <Flex onClick={navigateHandler} overrideStyle={{ cursor: "pointer" }}>
      <StepBackArrowIcon />
    </Flex>
  );
};

export default PreviousNavigator;
