import { Request } from "HttpRequest";
import {
  CREATE_KYC_USER,
  CUSTOMER_SCORE,
  DOWNLOAD_REPORT,
  FETCH_REPORT_LIST,
  GET_CUSTOMER_KYC_DEFINITION,
  GET_JORDAN_CUSTOMER_KYC,
  GET_JORDAN_STATUS,
  GET_LOAN_DETAILS,
  JORDAN_DEVICE_NO_VALIDATE,
  JORDAN_DOWNPAYMENT,
  PARTNER_CODE,
  PRODUCT_TYPE,
  REDIRECT_URL,
} from "HttpRequest/url";
import { Toast } from "Component";
import { getPathData, buildURL } from "Utils/helpers";
import {
  KYCID,
  XTENANTID,
  VALIDATE_JORDAN_NID,
  FETCH_JORDAN_DEVICE_LIST,
  FETCH_JORDAN_DOWNPAYMENT_BOUNDARIES,
  FETCH_JORDAN_EMI_VARIANTS,
  INITIATE_JORDAN_LOAN,
  DISBURSE_JORDAN_LOAN,
  GET_PAYMENT_OPTIONS,
  INITIATE_LOAN_JOURNEY,
} from "HttpRequest/url";
import {
  CUSTOMER_SCORE_2000,
  CUSTOMER_SCORE_4000,
  CUSTOMER_SCORE_4001,
  CUSTOMER_SCORE_4002,
} from "Global/statusCodes";
import { fetchMetaData } from "Global/commonRequest";
import { clearCookies } from "Global/helper";
import { EXISTINGLOAN_STATUS } from "Global/constant";
const { toast } = Toast;
export const createUser = ({ data }) => {
  const nid = getPathData(data, ["nid"], "");
  const formData = getPathData(data, ["formData"], {});
  const extraData = getPathData(data, ["extraData"], {});
  delete formData["requiredFields"];
  return new Promise((resolve, reject) => {
    Request({
      method: "POST",
      url: CREATE_KYC_USER,
      defaultHeaders: false,
      failureRedirection: true,
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": XTENANTID,
      },
      payload: {
        kycId: KYCID,
        created_by: nid,
        msisdn: nid,
        ...extraData,
        data: {
          ...formData,
        },
      },
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};
export const fetchCreditScore = ({ data, action }) => {
  Request({
    url: `${CUSTOMER_SCORE}`,
    method: "POST",
    payload: {
      ...data,
    },
    successAction: (resp) => {
      const status = getPathData(resp, ["data", "code"], "");
      if (
        status === CUSTOMER_SCORE_2000 ||
        status === CUSTOMER_SCORE_4000 ||
        status === CUSTOMER_SCORE_4001 ||
        status === CUSTOMER_SCORE_4002 ||
        status === EXISTINGLOAN_STATUS
      ) {
        action?.successCallback && action?.successCallback(status);
      } else {
        action.setIsLoading((prev) => ({ ...prev, loader: false }));
      }
    },
    failureAction: () => {
      toast.error("Something went wrong");
      action.setIsLoading((prev) => ({ ...prev, loader: false }));
    },
  });
};
export const fetchEligibility = ({ data, action }) => {
  const msisdn = getPathData(data, ["msisdn"], null);
  Request({
    url: GET_JORDAN_STATUS(msisdn),
    successAction: (resp) => {
      const statusCode = getPathData(resp, ["data", "code"], null);
      const getData = getPathData(resp, ["data", "data"], {});
      if (statusCode === 2000) {
        action?.successCallback &&
          action?.successCallback({
            data: getData,
            isVisible: true,
            type: "success",
            statusCode,
          });
      } else {
        action?.successCallback &&
          action?.successCallback({
            isVisible: true,
            type: "error",
            statusCode,
          });
        action?.setIsLoading((prev) => ({ ...prev, loader: false }));
      }
    },
    failureAction: () => {
      action?.setIsLoading((prev) => ({ ...prev, loader: false }));
      toast.error("Something went wrong");
    },
  });
};

export const confirmDownpaymentHandler = ({ payload }) => {
  return new Promise((resolve, reject) => {
    Request({
      method: "POST",
      url: JORDAN_DOWNPAYMENT,
      payload: {
        ...payload,
      },
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const ValidateNID = ({ params, actions }) => {
  Request({
    url: `${VALIDATE_JORDAN_NID}?msisdn=${params?.nid}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}`,
    successAction: (resp) => {
      actions?.successCallBack(resp);
    },
    failureAction: () => {
      toast.error(actions?.translate("something_wrong"));
      actions?.setIsLoading &&
        actions?.setIsLoading({ loader: false, msg: "" });
      actions?.failureCallback && actions?.failureCallback();
    },
  });
};

export const fetchDevices = ({ msisdn, contact_number, shop_id, pco_id }) => {
  return new Promise((resolve, reject) => {
    Request({
      url: `${FETCH_JORDAN_DEVICE_LIST}?msisdn=${msisdn}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&contact_number=${contact_number}&shop_id=${shop_id}&pco_id=${pco_id}`,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};
export const fetchLoanBoundaries = ({
  msisdn,
  shop_id,
  item_code,
  contact_number,
  pco_id,
}) => {
  return new Promise((resolve, reject) => {
    Request({
      url: `${FETCH_JORDAN_DOWNPAYMENT_BOUNDARIES}?msisdn=${msisdn}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&shop_id=${shop_id}&item_code=${item_code}&contact_number=${contact_number}&pco_id=${pco_id}`,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const fetchEmiVariants = ({
  msisdn,
  shop_id,
  item_code,
  downpayment_amount,
  pco_id,
}) => {
  return new Promise((resolve, reject) => {
    Request({
      url: `${FETCH_JORDAN_EMI_VARIANTS}?msisdn=${msisdn}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&shop_id=${shop_id}&item_code=${item_code}&downpayment_amount=${downpayment_amount}&pco_id=${pco_id}`,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const initiateLoan = (params) => {
  return new Promise((resolve, reject) => {
    Request({
      method: "POST",
      payload: { ...params },
      url: INITIATE_JORDAN_LOAN,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const logoutHandler = ({ action }) => {
  action?.setIsLoading({ loader: true, msg: action?.t("login_out") });
  localStorage?.removeItem("downpayment_details");
  clearCookies();
  localStorage.setItem("language", "en");
  toast.error("Unauthenticated");
  setTimeout(() => {
    fetchMetaData({ setIsLoading: null });
    window.location.href = REDIRECT_URL;
  }, 200);
};

export const loanDisbursement = (params) => {
  return new Promise((resolve, reject) => {
    Request({
      method: "POST",
      payload: { ...params },
      url: DISBURSE_JORDAN_LOAN,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const getPaymentOptions = ({ nid, pco_id, instrument_id }) => {
  return new Promise((resolve, reject) => {
    Request({
      url: `${GET_PAYMENT_OPTIONS}?partner_code=${PARTNER_CODE}&msisdn=${nid}&pco_id=${pco_id}&instrument_id=${instrument_id}`,
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const saveKycToLMS = (params) => {
  return new Promise((resolve, reject) => {
    Request({
      url: INITIATE_LOAN_JOURNEY,
      method: "POST",
      payload: {
        ...params,
      },
      successAction: (res) => {
        resolve(res);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const fetchLoanDetail = ({ data }) => {
  let queryParam = data?.instrument_id
    ? `&pco_id=${data?.pco_id}&instrument_id=${data?.instrument_id}`
    : `&pco_id=${data?.pco_id}`;
  return new Promise((resolve, reject) => {
    Request({
      url: `${GET_LOAN_DETAILS}?msisdn=${data?.nid}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}${queryParam}`,
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const fetchReports = ({ data }) => {
  const finalURL = buildURL(FETCH_REPORT_LIST, data);
  return new Promise((resolve, reject) => {
    Request({
      url: finalURL,
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const fetchDownloadedReports = ({ data }) => {
  const finalURL = buildURL(DOWNLOAD_REPORT, data);
  return new Promise((resolve, reject) => {
    Request({
      url: finalURL,
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const downloadReports = ({ data }) => {
  return new Promise((resolve, reject) => {
    Request({
      url: DOWNLOAD_REPORT,
      method: "POST",
      payload: {
        ...data,
      },
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};

export const fetchCustomerDetails = ({ kyc_submission_id }) => {
  return new Promise((resolve, reject) => {
    Request({
      url: GET_JORDAN_CUSTOMER_KYC(kyc_submission_id),
      defaultHeaders: false,
      failureRedirection: true,
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": XTENANTID,
      },
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};
export const getCustomeKycDefinition = () => {
  return new Promise((resolve, reject) => {
    Request({
      url: GET_CUSTOMER_KYC_DEFINITION,
      defaultHeaders: false,
      failureRedirection: true,
      headers: {
        "Content-Type": "application/json",
        "x-tenant-id": XTENANTID,
      },
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: (err) => {
        reject(err);
      },
    });
  });
};

export const validateDeviceId = ({ data }) => {
  return new Promise((resolve, reject) => {
    Request({
      url: `${JORDAN_DEVICE_NO_VALIDATE}?msisdn=${data?.nid}&contact_number=${data?.contact_number}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&shop_id=${data?.shop_id}&device_no=${data?.device_no}&item_code=${data?.item_code}&pco_id=${data?.pco_id}&instrument_id=${data?.instrument_id}`,
      successAction: (resp) => {
        resolve(resp);
      },
      failureAction: () => {
        reject();
      },
    });
  });
};
