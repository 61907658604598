import React, { useContext, useState } from "react";
import { Flex, Modal, Typography } from "Component";
import { createUseStyles } from "react-jss";
import { RetailerJordanColors } from "Global/colors";
import { getPathData, truncateText } from "Utils/helpers";
import JordanButton from "./jordanButton";
import ProductModal from "./productModal";
import Theme from "Global/retailerJordanTheme";
import { useTranslation } from "react-i18next";
import UITranslator from "Hooks/uiTranslator";
import { fetchLoanBoundaries } from "RetailerJordan/service";
import JordanLoader from "./jordanLoader";
import ErrorCard from "./errorCard";
import Cookies from "js-cookie";
import { StepperContext } from "RetailerJordan/Context/stepperContext";
import { useParams } from "react-router-dom";
import { EXISTINGLOAN_STATUS } from "Global/constant";
import useTimoutHook from "RetailerJordan/Hooks/useTimoutHook";
const useStyles = createUseStyles({
  productCardWrapper: {
    flex: 1,
    maxWidth: "calc(50% - 8px)",
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    borderRadius: Theme.padding / 2,
    boxSizing: " border-box",
    flexBasis: "50%",
    height: 101,
    "@media (max-width: 767px)": {
      flexBasis: "100%",
      maxWidth: "100%",
      marginRight: Theme.padding,
    },
  },
  imageWrapper: {
    width: "20%",
    paddingTop: Theme.padding,
    paddingBottom: Theme.padding,
    backgroundColor: RetailerJordanColors.PLACEHOLDER_GREY,
    marginRight: Theme.padding * 2,
    "& img": {
      width: "100%",
      height: Theme.padding * 10,
      objectFit: "cover",
    },
  },
  imageWrapperRight: {
    marginRight: 0,
    marginLeft: Theme.padding * 2,
  },
  contentWrapper: {
    width: "50%",
  },
  linkWrapper: {
    width: "30%",
    display: "block",
    "& div": {
      textAlign: "center",
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  heading: {
    marginBottom: Theme.padding / 2,
    maxHeight: 50,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  overrideModalParent: {
    width: "50%",
    height: "80%",
    "@media (max-width: 767px)": {
      width: "95%",
    },
  },
  buttonClass: {
    marginTop: 0,
  },
  footer: {
    paddingTop: Theme.padding * 2,
    paddingLeft: 0,
    paddingRight: Theme.padding * 3,
  },
  overrideModalHeader: {
    color: RetailerJordanColors.GREY900,
  },
  pointClass: {
    color: `${RetailerJordanColors.GREY500}!important`,
    marginBottom: Theme.padding,
  },
  priceClass: {
    fontSize: Theme.padding * 3,
    color: `${RetailerJordanColors.GREY900}!important`,
    marginBottom: Theme.padding / 2,
  },
  discountClass: {
    color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
  },
  directionChange: {
    direction: "rtl",
  },
  rightModalHeader: {
    direction: "rtl",
    paddingRight: Theme.margin * 2,
    paddingLeft: 0,
  },
  taxClass: {
    paddingLeft: Theme.margin / 2,
    paddingTop: Theme.margin / 2,
  },
});

const ProductCard = ({
  productDetails,
  onChooseItem,
  pco_id,
  testID = "productCard",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { nid } = useParams();
  const lang = UITranslator();
  const [timeoutHandler] = useTimoutHook();
  const { stepperState } = useContext(StepperContext);
  const { kyc_data } = stepperState;
  const { itemDesc, catDesc, subCatDesc, id, item_cost_with_margin } =
    productDetails;

  const [show, setShow] = useState(false);
  const [itemDetail, setItemDetail] = useState({
    item_cost_with_margin: "0",
  });
  const [errorData, setErrorData] = useState({
    isError: false,
    heading: "",
    subHeading: "",
  });
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: t("fetching_details"),
  });
  const { loader, msg } = isLoading;
  const closeModal = () => {
    setItemDetail({
      item_cost_with_margin: "0",
    });
    setShow(false);
  };

  const showModal = () => {
    setShow(true);
    setIsLoading({ loader: true, msg: t("fetching_details") });
    setErrorData({
      isError: false,
      heading: "",
      subHeading: "",
    });
    fetchLoanBoundaries({
      msisdn: nid,
      shop_id: Cookies.get("shop_id"),
      item_code: productDetails?.itemCode,
      contact_number: kyc_data?.contact_number,
      pco_id,
    })
      .then((res) => {
        const status = getPathData(res, ["data", "code"], null);
        const boundaryData = getPathData(res, ["data", "data"], {});
        if (status === 2000) {
          setItemDetail({
            ...boundaryData,
            min_downpayment_amount_with_margin:
              boundaryData?.min_downpayment_amount_with_margin,
            max_downpayment_amount_with_margin:
              boundaryData?.max_downpayment_amount_with_margin || 1,
            downpayment_amount:
              boundaryData?.min_downpayment_amount_with_margin,
          });
        } else if (status === EXISTINGLOAN_STATUS) {
          timeoutHandler();
          return;
        } else {
          setErrorData({
            isError: true,
            heading: "something_wrong",
            subHeading: "please_try_again_after_sometime",
          });
        }
        setIsLoading({ loader: false, msg: "" });
      })
      .catch(() => {
        setIsLoading({ loader: false, msg: "" });
        setErrorData({
          isError: true,
          heading: "something_wrong",
          subHeading: "please_try_again_after_sometime",
        });
      });
  };

  return (
    <Flex overrideClass={`${classes.productCardWrapper} `} key={id}>
      <Flex
        align="center"
        overrideClass={lang ? classes.directionChange : undefined}
        testID={testID}
      >
        <div
          className={`${classes.imageWrapper} ${
            lang ? classes.imageWrapperRight : undefined
          }`}
          data-testid="mobile_image"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/grey-image-placeholder.jpg"}
            alt="product-img"
          />
        </div>
        <div className={classes.contentWrapper} data-testid="mobile_desc">
          <Typography
            type="fontSmallBold"
            color={RetailerJordanColors.GREY900}
            overrideClass={classes.heading}
          >
            {itemDesc}
          </Typography>
          <Typography
            // type="p"
            type="fontXSmallSemiBold"
            color={RetailerJordanColors.GREY500}
          >
            {truncateText(
              `${item_cost_with_margin} JOD | ${catDesc} | ${subCatDesc}`,
              28
            )}
          </Typography>
        </div>
        <div className={classes.linkWrapper} onClick={showModal}>
          <Typography
            type="fontSmallBold"
            color={RetailerJordanColors.PRIMARY}
            testID={`${productDetails.id}_choosebtn`}
          >
            {t("choose")}
          </Typography>
        </div>
      </Flex>
      <Modal
        open={show}
        onClose={closeModal}
        overrideModalHeader={`${classes.overrideModalHeader} ${
          lang && classes.rightModalHeader
        }`}
        buttonHandler={closeModal}
        overrideModalParent={classes.overrideModalParent}
        showFooter={loader || errorData?.isError ? false : true}
        titleComponent={() => <Typography type="h3">{itemDesc}</Typography>}
        footerComponent={() => (
          <Flex
            align="center"
            overrideClass={`${classes.footer} ${
              lang && classes.directionChange
            }`}
          >
            {parseInt(itemDetail?.item_cost_with_margin) === 0 ||
            loader ||
            errorData?.isError ? null : (
              <Flex direction="column">
                <Flex>
                  <Typography type="h3" overrideClass={classes.priceClass}>
                    {itemDetail?.item_cost_with_margin} JOD
                  </Typography>
                  <Typography type="p" overrideClass={classes.taxClass}>
                    {(itemDetail?.tax16 !== "Not Taxable" ||
                      itemDetail?.tax26 !== "Not Taxable") &&
                      "+taxes"}
                  </Typography>
                </Flex>
                {/* <Typography type="p" overrideClass={classes.pointClass}>
                <s>{product?.total}</s>{" "}
                <span className={classes.discountClass}>
                  {product?.discount}%
                </span>
              </Typography> */}
              </Flex>
            )}
            <Flex>
              <JordanButton
                msg={t("choose_device")}
                customClass={classes.buttonClass}
                isValid={
                  parseInt(itemDetail?.item_cost_with_margin) === 0 ||
                  loader ||
                  errorData?.isError
                }
                onClickHandler={() =>
                  onChooseItem &&
                  parseInt(itemDetail?.item_cost_with_margin) > 0 &&
                  onChooseItem(itemDetail)
                }
                testID="choosedevicebtn"
              />
            </Flex>
          </Flex>
        )}
      >
        {loader ? (
          <JordanLoader label={msg} />
        ) : errorData?.isError ? (
          <ErrorCard
            heading={t(errorData?.heading)}
            subHeading={t(errorData?.subHeading)}
            showButton={false}
          />
        ) : (
          // <ProductModal images={images} product={productDetails} />
          <ProductModal />
        )}
      </Modal>
    </Flex>
  );
};

export default ProductCard;
