import React from "react";
const ToggleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M5.66699 23.5129C5.38366 23.5129 5.14616 23.417 4.95449 23.2253C4.76283 23.0335 4.66699 22.7959 4.66699 22.5125C4.66699 22.229 4.76283 21.9915 4.95449 21.8001C5.14616 21.6087 5.38366 21.5129 5.66699 21.5129H26.3336C26.6169 21.5129 26.8544 21.6088 27.0461 21.8006C27.2378 21.9923 27.3336 22.2299 27.3336 22.5134C27.3336 22.7968 27.2378 23.0343 27.0461 23.2257C26.8544 23.4172 26.6169 23.5129 26.3336 23.5129H5.66699ZM5.66699 17.0001C5.38366 17.0001 5.14616 16.9042 4.95449 16.7125C4.76283 16.5207 4.66699 16.2831 4.66699 15.9997C4.66699 15.7162 4.76283 15.4787 4.95449 15.2873C5.14616 15.0959 5.38366 15.0001 5.66699 15.0001H26.3336C26.6169 15.0001 26.8544 15.096 27.0461 15.2877C27.2378 15.4795 27.3336 15.7171 27.3336 16.0005C27.3336 16.284 27.2378 16.5215 27.0461 16.7129C26.8544 16.9043 26.6169 17.0001 26.3336 17.0001H5.66699ZM5.66699 10.4873C5.38366 10.4873 5.14616 10.3914 4.95449 10.1996C4.76283 10.0079 4.66699 9.7703 4.66699 9.48683C4.66699 9.20339 4.76283 8.96595 4.95449 8.7745C5.14616 8.58303 5.38366 8.4873 5.66699 8.4873H26.3336C26.6169 8.4873 26.8544 8.58318 27.0461 8.77493C27.2378 8.96669 27.3336 9.20429 27.3336 9.48774C27.3336 9.7712 27.2378 10.0087 27.0461 10.2001C26.8544 10.3915 26.6169 10.4873 26.3336 10.4873H5.66699Z"
        fill="#29242D"
      />
    </svg>
  );
};

export default ToggleIcon;
