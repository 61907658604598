import React from "react";
const LogoutIcon = (props) => {
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADO0lEQVR4nO2ZS0hVURSGv5taaWUZRFb0oKKRA4noKU2i6P0g8EKCZWDDZkHDKCxoUEhZQdSoSZlOapRSBAVFRM9B16QyEyK0d6ZJ3tiwhMO+++h1333OuTf8Yc32evxnr7P2XmvDGP5fzAAqgTNAC/Ae+AEkRb4DXUCrrNkjOlmBQmAvcBv46wk6XVE694EaYFJUBA4BHy2C95Me4HCYhLYCbxwS0KUL2BkkgYnAhWECGAQeAHXAdmAJMNmjPwVYDOwAjgEPR0jHK0HszkzgsY/DLxL8Qgu7C4RUt4/tJ8B8VySUs3aDk37gBFDswMdUIdRv8NMJLHKxE68Nxl8CZbhHmeyC7k+V8tm2RicAjwxGm4AigkMRcM3g97StwbMGY5eBPILHOOCS5rvextAGA4nmkEh4ydTJ+XIPmIVFSr3SSLRJ+cwpHNRIDADl5BjGS4XwEjkVUSx5QMxWuVIjoW6v0wkfNcBP4BOwy8bAzSzYjTy58ntTOz4aA+pn/qMRCeLQS6da9Rj+07TJbDac3lFhtwRvRabOxQHkEHFbMs2aUhXRI25D5oWmsIzsQHy0ZD5oi61vm1LGXbbBSYMMSJOWgm/awuIMqo5fo+RaOkwBfNUWqWbHBrEQdiMpohq+FHRqi+Zgj02Gi6dr6QPWmZw/0xauIDtQK4MNncQWP4VGbXE1OUhC4aimcI4cJKGwXlNKEB2qbEkMNf19mvJSokGPLYkhNGkGGogG3ZmQQE5KL5FeoDSYWEeM452UcGOJTaepadPIqHlvTqJWI6IGzavIQeQDzzUyb4GSCGOK2SpWGEb+LTJlCQsFcpYNSouhhulWOG6431wPiUyB+HJSQVWK3TGQaQ04zUqAWwa/JzMxOs1wmUxKaVyDe1T4vMUkXLwAl/qQUf/QRZvhsgHKxnmfp7hEht1qys6Y0kzJbwnCpscvl9z/5WP7bhBv8fkyMhruAbNdDtB9wEpgruS8+hDzgOUyDm3wSaGkZ7frgy4sq4GnAXZ+CWAtIUHtzn6f90VbUa32ASm9oUPdzbbJm1+vRfDq/7ohl0T1cbIChXJTPQJclfRT45rP8hbfIVefRulGNwb8qDoGosQ/71ZLR0PsvK0AAAAASUVORK5CYII="
      {...props}
    />
  );
};

export default LogoutIcon;
