import { useTranslation } from "react-i18next";

const UITranslator = () => {
  const { i18n } = useTranslation();
  if (i18n.language === "ar") {
    return true;
  } else {
    return false;
  }
};

export default UITranslator;
