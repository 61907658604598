import React, { useState, useEffect } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Label from "./label";
import Flex from "./flex";
import Typography from "./Typography";
import Colors from "Global/colors";
const useStyles = createUseStyles((theme) => ({
  container: {
    border: "none",
    width: "100%",
    // @ts-ignore
    padding: theme.padding / 2,
  },
  textAreaStyle: {
    width: "100%",
    padding: "8px 14px",
    boxSizing: "border-box",
    // -webkit-transition: 0.5s,
    transition: "0.1s",
    outline: "none",
    // @ts-ignore
    borderRadius: theme.borderRadius,
    border: `1px solid ${Colors.BORDERCOLOR}`,
    color: Colors.FORM_TEXT_COLOR,
    "&:focus": {
      border: `2px solid ${theme?.primaryColor}`,
    },
  },
  borderRed: {
    border: `1px solid ${Colors.RED}`,
  },
}));
const TextArea = (props) => {
  const {
    handleChange = null,
    error = false,
    errorMsg = "",
    value = "",
    label = "",
    placeholder = "",
    disabled = false,
    containerStyle = {},
    containerClass = "",
    inputClass = "",
    helpText = null,
    rows = "4",
    columns = "5",
    showLabel = true,
    required = false,
    textBoxStyle = {},
    labelStyle = {},
    testID = "textareaID",
    helpTextColor = Colors.RED,
    ...extraProps
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [props]);

  const onChangeHandler = (e) => {
    let inputData = e.target.value;
    setInputValue(inputData);
    handleChange && handleChange(inputData);
  };
  const { textAreaStyle, borderRed, container } = classes;
  return (
    <div style={{ width: "100%" }}>
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${container} ${containerClass}`}
      >
        {showLabel && (
          <Label label={label} required={required} labelStyle={labelStyle} />
        )}
        <textarea
          rows={rows}
          cols={columns}
          value={inputValue}
          className={` ${textAreaStyle} ${inputClass} ${
            error ? borderRed : ""
          } `}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChangeHandler}
          required={required}
          style={{
            resize: "vertical",
            overflow: "auto",
            ...textBoxStyle,
            minHeight: "90px",
          }}
          data-testid={testID}
          {...extraProps}
        >
          {inputValue}
        </textarea>
        {error && <Typography color={Colors.RED}>{errorMsg}</Typography>}
        {helpText && <Typography color={helpTextColor}>{helpText}</Typography>}
      </Flex>
    </div>
  );
};

TextArea.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  containerStyle: PropTypes.object,
  textBoxStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  containerClass: PropTypes.string,
  errorMsg: PropTypes.string,
  helpText: PropTypes.string,
  classname: PropTypes.string,
  inputClass: PropTypes.string,
  rows: PropTypes.number,
  columns: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  testID: PropTypes.string,
  handleChange: PropTypes.func,
  minRows: PropTypes.number,
  helpTextColor: PropTypes.string,
};

export default TextArea;
