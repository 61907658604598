import { Flex } from "Component";
import React from "react";
import { StepBackArrowIcon } from "RetailerJordan/Assets/Icons";
const BackHandler = ({ overrideClass = "", onClick = null }) => {
  return (
    <Flex
      overrideStyle={{ cursor: "pointer" }}
      onClick={onClick}
      overrideClass={overrideClass}
    >
      <StepBackArrowIcon />
    </Flex>
  );
};

export default BackHandler;
