import React, { useState, useCallback, useMemo, useEffect } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
import Typography from "./Typography";
import Colors from "Global/colors";
import { getPathData } from "Utils/helpers";
const useStyles = createUseStyles((theme) => ({
  singlePage: {
    width: 30,
    height: 30,
    border: `1px solid ${Colors.BORDERCOLOR}`,
    // @ts-ignore
    borderRadius: theme.borderRadius,
    cursor: "pointer",
    // @ts-ignore
    margin: theme.margin / 4,
    "&:hover": {
      // @ts-ignore
      backgroundColor: theme?.primaryColorLight,
      color: Colors.WHITE,
      boxShadow: `0.2px 0.2px  ${Colors.BORDERCOLOR}`,
    },
  },
  paginationArrowFastNext: {
    cursor: "pointer",
    // @ts-ignore
    marginLeft: theme.margin,
    // @ts-ignore
    marginRight: theme.margin,
    transition: "0.2s all ease-in-out",
    "&:hover": {
      // @ts-ignore
      backgroundColor: theme?.primaryColorLight,
      borderRadius: 50,
      width: 25,
      height: 25,
      textAlign: "center",
      fontSize: 20,
    },
  },
  paginationArrowFastDisabled: {
    cursor: "pointer",
    // @ts-ignore
    marginLeft: theme.margin,
    // @ts-ignore
    marginRight: theme.margin,
    transition: "0.2s all ease-in-out",
  },
  paginationArrowFastPrev: {
    cursor: "pointer",
    // @ts-ignore
    marginLeft: theme.margin,
    // @ts-ignore
    marginRight: theme.margin,
    transition: "0.2s all ease-in-out",
    "&:hover": {
      // @ts-ignore
      backgroundColor: theme?.primaryColorLight,
      borderRadius: 50,
      width: 25,
      height: 25,
      textAlign: "center",
      fontSize: 20,
    },
  },
  paginationArrowNext: {
    cursor: "pointer",
    // @ts-ignore
    marginLeft: theme.margin,
    // @ts-ignore
    marginRight: theme.margin,
    transition: "0.2s all ease-in-out",
  },
  paginationArrowPrev: {
    cursor: "pointer",
    // @ts-ignore
    marginLeft: theme.margin,
    // @ts-ignore
    marginRight: theme.margin,
    transition: "0.2s all ease-in-out",
  },
  activePageStyle: {
    // @ts-ignore
    backgroundColor: theme?.primaryColorLight,
    color: Colors.WHITE,
    boxShadow: `0.2px 0.2px  ${Colors.BORDERCOLOR}`,
  },
  arrowPage: {
    // @ts-ignore
    width: theme?.margin * 3,
  },
}));
const positionValue = {
  left: "start",
  right: "end",
};
let isMounted = false;
const Pagination = (props) => {
  const {
    totalPages = 20,
    position = "right",
    maximumCountToDisplay = 5,
    onChangeHandler = null,
    testID = "paginationID",
    pageNumber = 1,
    overridePageStyle = "",
    overridePageNumberStyle = "",
    overrideArrowFastClass = "",
    overrideArrowPrevClass = "",
    overrideArrowNextFast = "",
    overrideArrowNextClass = "",
    prevIcon = "",
    nextIcon = "",
    ...rest
  } = props;
  const count = Array(totalPages)
    .fill()
    .map((_, i) => i + 1);
  const [start, setStart] = useState(0);
  const [activePage, setActivePage] = useState(pageNumber);
  const isFastNextDisabled = useMemo(
    () => Math.abs(start + maximumCountToDisplay) === totalPages,
    [activePage, start]
  );
  const isFastPrevDisabled = useMemo(() => !start, [activePage, start]);
  const onPressFastPrevious = useCallback(() => {
    if (!isFastPrevDisabled) {
      setActivePage(start - maximumCountToDisplay + 1);
      setStart(start - maximumCountToDisplay);
    }
  }, [activePage, start]);
  const onPressFastNext = useCallback(() => {
    if (!isFastNextDisabled) {
      setActivePage(start + maximumCountToDisplay + 1);
      setStart(start + maximumCountToDisplay);
    }
  }, [activePage, start]);
  const isPrevDisabled = useMemo(() => activePage <= 1, [activePage, start]);
  const isNextDisabled = useMemo(
    () => activePage >= totalPages,
    [activePage, start]
  );
  const onPressPrevious = useCallback(() => {
    if (!isPrevDisabled) {
      if (Math.abs(activePage - start) === 1) {
        setStart(start - maximumCountToDisplay);
      }
      setActivePage((prev) => prev - 1);
    }
  }, [activePage, start]);
  const onPressNext = useCallback(() => {
    if (!isNextDisabled) {
      if (Math.abs(activePage - start) === maximumCountToDisplay) {
        setStart(start + maximumCountToDisplay);
      }
      setActivePage((prev) => prev + 1);
    }
  }, [activePage, start]);
  useEffect(() => {
    setActivePage(pageNumber);
  }, [props.pageNumber]);
  const classes = useStyles({
    ...props,
    isFastNext: !isFastNextDisabled,
    isFastPrev: !isFastPrevDisabled,
  });
  const {
    singlePage,
    paginationArrowFastPrev,
    paginationArrowFastNext,
    paginationArrowNext,
    paginationArrowPrev,
    activePageStyle,
    paginationArrowFastDisabled,
  } = classes;
  const getPaginationBar = () => {
    const modifiedList = count.splice(start, maximumCountToDisplay);
    return (
      <>
        <Typography
          type="h3"
          overrideClass={`${
            isFastPrevDisabled
              ? paginationArrowFastDisabled
              : paginationArrowFastPrev
          } ${overrideArrowFastClass}`}
          onClick={onPressFastPrevious}
          disabled={isFastPrevDisabled}
          data-testid="fast-prev"
        >
          &laquo;
        </Typography>
        <Typography
          type="h3"
          disabled={isPrevDisabled}
          overrideClass={`${paginationArrowPrev} ${overrideArrowPrevClass}`}
          onClick={onPressPrevious}
          data-testid="prev"
        >
          {prevIcon ? (
            <img
              src={prevIcon}
              className={classes.arrowPage}
              alt="preview-icon"
            />
          ) : null}
        </Typography>
        {modifiedList.map((res, key) => {
          return (
            <Flex
              isFlex={true}
              justify="center"
              align="center"
              key={key}
              onClick={() => setActivePage(res)}
              overrideClass={`${singlePage} ${overridePageStyle} ${
                activePage === res
                  ? ` ${activePageStyle} ${overridePageNumberStyle}`
                  : ""
              }`}
              data-testid={`pagination_${key}`}
            >
              <Typography>{res}</Typography>
            </Flex>
          );
        })}
        <Typography
          type="h3"
          overrideClass={`${paginationArrowNext} ${overrideArrowNextFast}`}
          onClick={onPressNext}
          disabled={isNextDisabled}
          data-testid="next"
        >
          {nextIcon ? (
            <img src={nextIcon} className={classes.arrowPage} alt="next-icon" />
          ) : (
            "›"
          )}
        </Typography>
        <Typography
          type="h3"
          overrideClass={`${
            isFastNextDisabled
              ? paginationArrowFastDisabled
              : paginationArrowFastNext
          } ${overrideArrowNextClass}`}
          onClick={onPressFastNext}
          disabled={isFastNextDisabled}
          data-testid="fast-next"
        >
          &raquo;
        </Typography>
      </>
    );
  };
  useEffect(() => {
    if (isMounted) {
      onChangeHandler && onChangeHandler(activePage);
    }
    isMounted = true;
  }, [activePage]);
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <Flex
      align="center"
      justify={getPathData(positionValue, [position], "end")}
      data-testid={testID}
      {...rest}
    >
      {getPaginationBar()}
    </Flex>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  position: PropTypes.oneOf(["left", "right"]),
  maximumCountToDisplay: PropTypes.number,
  testID: PropTypes.string,
  onChangeHandler: PropTypes.func,
  pageNumber: PropTypes.number,
  overridePageStyle: PropTypes.string,
  overridePageNumberStyle: PropTypes.string,
  overrideArrowFastClass: PropTypes.string,
  overrideArrowPrevClass: PropTypes.string,
  overrideArrowNextFast: PropTypes.string,
  overrideArrowNextClass: PropTypes.string,
  prevIcon: PropTypes.string,
  nextIcon: PropTypes.string,
};

export default Pagination;
