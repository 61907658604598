import { Flex, Typography } from "Component";
import { RetailerJordanColors } from "Global/colors";
import UITranslator from "Hooks/uiTranslator";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { JordanButton } from "RetailerJordan/Component";
import { RootProvider } from "RetailerJordan/Context";
import { getPathData } from "Utils/helpers";
const useStyles = createUseStyles({
  overrideModalParent: {
    width: 500,
    height: 350,
  },
  directionChange: {
    direction: "rtl",
  },
});
const TimoutHook = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = UITranslator();
  const RootContextValue = useContext(RootProvider);
  const openModal = getPathData(RootContextValue, ["openModal"], null);
  const closeModalHandler = getPathData(RootContextValue, ["closeModal"], null);
  const timeoutHandler = () => {
    openModal({
      isVisible: true,
      component: () => (
        <Flex
          direction="column"
          justify="between"
          overrideClass={lang && classes.directionChange}
        >
          <Flex direction="column" align="center" justify="center">
            <img
              src={process.env.PUBLIC_URL + "/assets/error.gif"}
              style={{ width: 150, height: 150 }}
              data-testid="errorCardGif"
            />
            <Typography
              color={RetailerJordanColors.GREY900}
              type="fontSmall"
              overrideStyle={{ textAlign: "center" }}
            >
              {t("terminated_installment_msg")}
            </Typography>
          </Flex>

          <JordanButton
            onClickHandler={() => {
              closeModalHandler();
              navigate(`/`);
            }}
            msg={t("create_installment")}
            testID="create_installment"
          />
        </Flex>
      ),
      extraModalProps: {
        size: "small",
        showHeader: false,
        overrideModalParent: classes.overrideModalParent,
      },
    });
  };
  return [timeoutHandler];
};

export default TimoutHook;
