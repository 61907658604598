import React, { useEffect, useState, Suspense } from "react";
import { Flex, Modal, Typography } from "Component";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { getPathData } from "Utils/helpers";
import Colors, { RetailerJordanColors } from "Global/colors";
import DeviceObserver from "Hooks/deviceDetect";
import { StepSuccessIcon, TickIcon } from "RetailerJordan/Assets/Icons";
import JordanLoader from "./jordanLoader";
import { useTranslation } from "react-i18next";
import UITranslator from "Hooks/uiTranslator";
const useStyles = createUseStyles((theme) => ({
  stepsMainContainer: {
    paddingLeft: theme.padding * 5,
    paddingRight: theme.padding * 5,
    backgroundColor: RetailerJordanColors.WHITE,
    "@media (max-width: 550px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepsChildContainer: {
    overflow: "hidden",
    "&:hover": {
      overflowX: "auto",
    },
    width: 344,
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    height: "max-content",
    borderRadius: theme?.borderRadius,
    position: "fixed",
    backgroundColor: RetailerJordanColors.WHITE,
  },
  numberIndicator: {
    width: 20,
    height: 20,
    padding: theme?.padding / 2,
    borderRadius: 20,
    // @ts-ignore
    marginRight: theme?.margin,
    // @ts-ignore
    backgroundColor: (props) => props.unvisitedStepColor,
  },
  activeStepClass: {
    // @ts-ignore
    backgroundColor: (props) => props.activeStepColor,
    color: Colors.WHITE,
  },
  previousStepClass: {
    width: 24,
    height: 24,
    // @ts-ignore
    marginRight: theme?.margin,
  },
  verticalCardDivider: {
    width: 1,
    height: "100%",
    marginLeft: theme.margin * 5,
    marginRight: theme.margin * 5,
    background: RetailerJordanColors.BORDERCOLOR_10,
  },
  stepContainer: {
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
    borderBottom: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
  },
  activeStepContainer: {
    background: RetailerJordanColors.BRAND25,
    borderLeft: `2px solid ${RetailerJordanColors.PRIMARY_DARK}`,
  },
  childStepContainer: {
    // @ts-ignore
    height: 56,
    paddingLeft: theme?.padding * 6,
    paddingRight: theme?.padding * 3,
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
  },
  childStepActiveContainer: {
    background: RetailerJordanColors.BRAND50,
  },
  parentLabelContainer: {
    paddingLeft: theme?.padding * 3,
    paddingRight: theme?.padding * 3,
    height: 68,
  },
  changeDirection: {
    direction: "rtl",
  },
  arIndicator: {
    marginLeft: theme.padding,
  },
  arChildStepContainer: {
    paddingLeft: "24px",
    paddingRight: "48px",
  },
  overrideViewClass: {
    fontWeight: "600",
    textDecoration: "underline",
    marginRight: theme.padding,
  },
  directionChange: {
    direction: "rtl",
  },
  overrideModalHeader: {
    paddingRight: theme.padding * 2,
  },
}));
const JordanStepper = (props) => {
  const {
    options = [],
    onSelectStep = null,
    parentStep = 0,
    childStep = null,
    renderFallBack = null,
    activeStepColor = RetailerJordanColors.PRIMARY,
    previousStepColor = RetailerJordanColors.PRIMARY,
    unvisitedStepColor = RetailerJordanColors.GREY200,
    overrideStepMainContainer = "",
    overrideStepContainer = "",
    overrideNumberContainer = "",
    overrideContainer = "",
    overrideIndexClass = "",
    overrideLabelClass = "",
    stepClickable = false,
    overrideLabelStyle = {},
    testID = "stepperID",
    overrideGridStyle = "",
    overrideComponentClass = "",
    ...rest
  } = props;
  const { isDesktop } = DeviceObserver();
  const lang = UITranslator();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState({
    parentStep: 0,
    childStep: null,
  });
  const [showModal, setShowModal] = useState({
    component: null,
    isVisible: false,
    title: "",
  });
  const classes = useStyles({
    ...props,
    activeStep,
    activeStepColor,
    previousStepColor,
    unvisitedStepColor,
  });
  const {
    stepsMainContainer,
    stepContainer,
    numberIndicator,
    activeStepClass,
    previousStepClass,
    stepsChildContainer,
    activeStepContainer,
    childStepContainer,
    childStepActiveContainer,
    parentLabelContainer,
    changeDirection,
    arIndicator,
    arChildStepContainer,
    overrideViewClass,
  } = classes;
  const refs = options.reduce((acc, value, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});
  const onClickHandler = (tabNumber) => {
    if (onSelectStep) onSelectStep(tabNumber);
    if (tabNumber > activeStep) {
      if (refs[tabNumber + 3]) {
        refs[tabNumber + 3].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else
        refs[options.length - 1].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    } else {
      if (refs[tabNumber - 2])
        refs[tabNumber - 2].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      else
        refs[0].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  };
  const isParentPrevious = (key) => {
    return key < activeStep?.parentStep;
  };
  const isParentActive = (key) => {
    return key === activeStep?.parentStep;
  };
  const isChildActive = (key) => {
    return key === activeStep?.childStep;
  };
  const isChildPrevious = (key) => {
    return key < activeStep?.childStep;
  };
  useEffect(() => {
    setActiveStep({
      parentStep,
      childStep,
    });
  }, [props.parentStep, props.childStep]);
  let renderComponent = null;
  const isChildrenAvailable = getPathData(
    options,
    [activeStep.parentStep, "children"],
    []
  );
  if (isChildrenAvailable && isChildrenAvailable?.length > 0) {
    renderComponent = getPathData(
      options,
      [activeStep.parentStep, "children", activeStep?.childStep, "component"],
      null
    );
  } else {
    renderComponent = getPathData(
      options,
      [activeStep.parentStep, "component"],
      null
    );
  }

  const closeModal = () => {
    setShowModal({
      component: null,
      isVisible: false,
      title: "",
    });
  };

  const openModal = (result) => {
    setShowModal({
      component: result?.modalProps?.modalComponent,
      isVisible: true,
      title: result?.modalProps?.title,
    });
  };
  return (
    <Flex
      overrideClass={`${stepsMainContainer} ${overrideContainer}`}
      overrideStyle={{ ...overrideGridStyle }}
    >
      {isDesktop && (
        <Flex
          direction="column"
          overrideClass={`${stepsChildContainer} ${overrideStepMainContainer} ${
            lang ? changeDirection : undefined
          }`}
          testID={testID}
          {...rest}
        >
          {options.map((res, key) => {
            const isDisabled = getPathData(res, ["isDisabled"], false);
            return (
              <Flex
                key={key}
                overrideClass={`${stepContainer} ${
                  isParentActive(key) ? activeStepContainer : ""
                } ${overrideStepContainer}`}
                direction="column"
                justify="between"
                onClick={() => {
                  if (stepClickable && !isDisabled) onClickHandler(key);
                }}
                overrideStyle={{
                  cursor: isDisabled ? "pointer" : "cursor",
                }}
                innerRef={refs[key]}
                testID={`stepper_${key}`}
              >
                <Flex align="center" overrideClass={parentLabelContainer}>
                  {isParentPrevious(key) ? (
                    <Flex
                      overrideClass={`${previousStepClass}
                           ${overrideNumberContainer} ${
                        lang ? arIndicator : undefined
                      }
                           `}
                      justify="center"
                      align="center"
                    >
                      <StepSuccessIcon />
                    </Flex>
                  ) : (
                    <Flex
                      overrideClass={`${numberIndicator} ${
                        lang ? arIndicator : undefined
                      }
                      ${isParentActive(key) ? activeStepClass : ""}
                      ${overrideNumberContainer}
                      `}
                      justify="center"
                      align="center"
                    >
                      <Typography
                        overrideClass={overrideIndexClass}
                        type="fontXSmallBold"
                        color={RetailerJordanColors.WHITE}
                      >
                        {key + 1}
                      </Typography>
                    </Flex>
                  )}

                  <Typography
                    color={RetailerJordanColors.GREY800}
                    overrideClass={overrideLabelClass}
                    overrideStyle={overrideLabelStyle}
                    type="fontSmallBold"
                  >
                    {t(res?.label)}
                  </Typography>
                </Flex>
                {isParentActive(key) &&
                  res?.children?.map((result, key1) => {
                    return (
                      <Flex
                        key={key1}
                        direction="row"
                        justify="between"
                        align="center"
                        innerRef={refs[key1]}
                        overrideClass={`${childStepContainer} ${
                          lang ? arChildStepContainer : undefined
                        }
                      ${isChildActive(key1) ? childStepActiveContainer : ""}
                      `}
                        testID={`childstepper_${key1}`}
                      >
                        <Typography
                          color={RetailerJordanColors.GREY600}
                          overrideClass={overrideLabelClass}
                          overrideStyle={overrideLabelStyle}
                          type="fontSmall"
                        >
                          {t(result?.label)}
                        </Typography>

                        {isChildPrevious(key1) ? (
                          <Flex
                            direction="row"
                            justify="end"
                            align="center"
                            overrideStyle={{ width: "initial" }}
                          >
                            {result?.isViewButton && (
                              <Typography
                                color={RetailerJordanColors.PRIMARY}
                                overrideClass={overrideViewClass}
                                type="fontSmall"
                                onClick={() => openModal(result)}
                                testID="viewbtn_link"
                              >
                                {t("view")}
                              </Typography>
                            )}
                            <TickIcon />
                          </Flex>
                        ) : null}
                      </Flex>
                    );
                  })}
              </Flex>
            );
          })}
        </Flex>
      )}
      <Modal
        open={showModal?.isVisible}
        onClose={closeModal}
        buttonHandler={closeModal}
        titleComponent={() => (
          <Typography type="h3">{showModal?.title}</Typography>
        )}
        overrideModalParent={lang ? classes.directionChange : undefined}
        overrideModalHeader={lang ? classes.overrideModalHeader : undefined}
      >
        <Suspense
          fallback={renderFallBack ? renderFallBack() : <JordanLoader />}
        >
          {showModal?.component ? showModal?.component() : null}
        </Suspense>
      </Modal>
      <Flex overrideClass={overrideComponentClass}>
        {isDesktop && <div className={classes.verticalCardDivider}></div>}
        <Flex
          overrideStyle={{ flexGrow: 1, overflowY: "hidden" }}
          testID="rendredcomponent_id"
        >
          <Suspense
            fallback={renderFallBack ? renderFallBack() : <JordanLoader />}
          >
            {renderComponent ? renderComponent() : null}
          </Suspense>
        </Flex>
      </Flex>
    </Flex>
  );
};

JordanStepper.propTypes = {
  options: PropTypes.array,
  overrideContainer: PropTypes.string,
  overrideStepMainContainer: PropTypes.string,
  overrideStepContainer: PropTypes.string,
  overrideLabelClass: PropTypes.string,
  overrideStepBar: PropTypes.string,
  overrideNumberContainer: PropTypes.string,
  overrideIndexClass: PropTypes.string,
  overrideLabelStyle: PropTypes.object,
  stepClickable: PropTypes.bool,
  testID: PropTypes.string,
  onSelectStep: PropTypes.func,
  renderFallBack: PropTypes.func,
  parentStep: PropTypes.number,
  childStep: PropTypes.number,
  overrideGridStyle: PropTypes.object,
  overrideComponentClass: PropTypes.string,
};
export default JordanStepper;
