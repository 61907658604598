export const ModalActions = {
  TOGGLE_MODAL: "TOGGLE_MODAL",
};
export const LoaderActions = {
  TOGGLE_LOADER: "TOGGLE_LOADER",
};

export const CommonAction = {
  UPDATE_STATE: "UPDATE_STATE",
};
