import React from "react";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  imgStyle: {
    width: 15,
    height: 15,
  },
});
const EditIcon = (props) => {
  const { overrideStyle = {}, overrideClass = "" } = props;
  const classes = useStyles();
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAuklEQVRIie3VoQrCQAAG4A+x+xJisxnMghisRl9iyahN38Jq8Wl8AItVMVk16GAOhxN2N4T9cOF2cN/9HOxo8udJcMEBg1joCvfMuGIYG83ib81boU/ySgfb0EhR63MIaFUCT6pG0403ubVFZm0ZCk3Hp+b5b5WjRc2joMHwMmiUO23QBv05E8/39Bta6c8hzbwOFMZ1oNCLifZzG3cxwgy3UCisFbeahkLhKNI9tnPz0wvdYR8SbhItDwJbnBVPxJOqAAAAAElFTkSuQmCC"
      style={{ ...overrideStyle }}
      className={`${classes.imgStyle} ${overrideClass}`}
    />
  );
};

export default EditIcon;
