import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { Flex, Typography } from "Component";
import Theme from "Global/retailerJordanTheme";
import { RetailerJordanColors } from "Global/colors";
import JordanButton from "./jordanButton";

const useStyles = createUseStyles({
  cardContainer: {
    width: 592,
    borderRadius: Theme.borderRadius - 4,
    paddingLeft: Theme.padding * 10,
    paddingRight: Theme.padding * 10,
    boxSizing: "border-box",
    "@media (max-width: 1185px)": {
      width: "100%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 550px)": {
      width: "100%",
    },
  },
  headingContainerClass: {
    paddingTop: Theme.padding * 3,
    paddingBottom: Theme.padding * 3,
  },
  customClass: {
    "@media (max-width: 768px)": {
      height: "100%",
    },
  },
});

const SuccessCard = (props) => {
  const {
    renderComponent = null,
    heading = "",
    subHeading = "",
    buttonText = "",
    onClickHandler,
    overrideHeaderContainerClass = "",
  } = props;
  const classes = useStyles();
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      overrideClass={classes.cardContainer}
      testID="successCard_id"
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/success.gif"}
        style={{ width: 56, height: 56 }}
      />
      <Flex
        direction="column"
        justify="center"
        align="center"
        overrideClass={`${classes.headingContainerClass} ${overrideHeaderContainerClass}`}
      >
        {!heading ? null : (
          <Typography
            color={RetailerJordanColors.GREY600}
            type="fontLargeBold"
            overrideStyle={{ textAlign: "center" }}
          >
            {heading}
          </Typography>
        )}
        {!subHeading ? null : (
          <Typography color={RetailerJordanColors.GREY600} type="fontSmall">
            {subHeading}
          </Typography>
        )}
      </Flex>
      {renderComponent && <Flex>{renderComponent}</Flex>}
      <JordanButton
        onClickHandler={onClickHandler}
        msg={buttonText}
        customClass={classes.customClass}
        testID="successCardButton"
      />
    </Flex>
  );
};

SuccessCard.propTypes = {
  renderComponent: PropTypes.node,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string,
  onClickHandler: PropTypes.func,
  overrideHeaderContainerClass: PropTypes.string,
};

export default SuccessCard;
