import React from "react";
const CardDownArrow = (props) => {
  const { overrideStyle = {}, overrideClass = "" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ ...overrideStyle }}
      className={overrideClass}
      data-testid="cardDownArrow_id"
    >
      <path
        d="M12 13.0308L9.44228 10.4731C9.30541 10.3346 9.13433 10.2654 8.92905 10.2654C8.72377 10.2654 8.54678 10.3346 8.39808 10.4731C8.24294 10.6218 8.16698 10.8 8.17018 11.0077C8.17339 11.2154 8.24936 11.3936 8.39808 11.5423L11.3673 14.5115C11.5481 14.6923 11.759 14.7827 12 14.7827C12.241 14.7827 12.4519 14.6923 12.6327 14.5115L15.6173 11.5269C15.766 11.3782 15.8394 11.2025 15.8375 11C15.8355 10.7974 15.757 10.6218 15.6019 10.4731C15.4532 10.3346 15.2775 10.2644 15.075 10.2625C14.8724 10.2606 14.6968 10.334 14.5481 10.4827L12 13.0308ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5Z"
        fill="#6B6572"
      />
    </svg>
  );
};

export default CardDownArrow;
