import React, { useEffect, useState, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Flex } from "Component";
import JordanLoader from "RetailerJordan/Component/jordanLoader";
import { ErrorCard } from "RetailerJordan/Component";
import { useTranslation } from "react-i18next";
const nidRegex = /^\d+$/;
const NidValidator = (props) => {
  const { nid } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isValidNid, setIsValidNid] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loader: true,
    msg: "Please Wait...",
  });
  useEffect(() => {
    setIsValidNid(nid !== "" && nid?.length === 10 && nid?.match(nidRegex));
    // const pco_id=searchParams?.get("pco_id");
    // const instrument_id=searchParams?.get("instrument_id");
    // if(pco_id!=="" && pco_id!==null){
    //   if(parseInt(pco_id)<=0){
    //     navigate("/")
    //   }
    // }
    // if(instrument_id!=="" && instrument_id!==null){
    //   if(parseInt(instrument_id)<=0){
    //     navigate("/")
    //   }
    // }
    setIsLoading({ loader: false, msg: "" });
  }, []);

  const { loader, msg } = isLoading;
  if (loader)
    return (
      <Flex overrideStyle={{ height: "50vh" }}>
        <JordanLoader label={msg} />
      </Flex>
    );
  if (!loader && isValidNid) return props.children;
  else
    return (
      <Suspense fallback={<JordanLoader label={msg} />}>
        <Flex justify="center" align="center">
          <ErrorCard
            heading={"Inavlid NID Number"}
            subHeading={"Please use valid nid number"}
            buttonText={t("go_home")}
            onPressHandler={() => navigate("/")}
          />
        </Flex>
      </Suspense>
    );
};

export default NidValidator;
