const DEVICE_PARTNER = "Samsung Knox";
const STARTING_HANDSET_ACTIVATION = "starting_handset_activation";
const HANDSET_ACTIVATED = "handset_activated";
const STARTING_RETAILER_CONFIRMATION = "starting_retailer_confirmation";
const STARTING_DOWNPAYMENT = "starting_dowpayment";
const CUSTOMER_CONFIRMED = "customer_confirmed";
const EVENTS_SCHEDULED = "events_scheduled";
const PARTNER_LOGO = localStorage.getItem("logo");
const SECRET_PASS = process.env.REACT_APP_SECRET_PASS;
const EXISTINGLOAN_STATUS = 7035;
// export const JordanKycKeys = {
//   "61d5be734849191e6600806f": "first_name",
//   "61d5d91e4849191e66008073": "last_name",
//   "61d5dab54849191e66008076": "gender",
//   "61d5dba94849191e66008079": "8888888882",
//   "65465d04006b7f16e8c7089c": "third_name",
//   "65465d04006b7f16e8c70889": "second_name",
//   "65465d04006b7f16e8c70895": "9571001142",
// };
export {
  DEVICE_PARTNER,
  HANDSET_ACTIVATED,
  STARTING_HANDSET_ACTIVATION,
  STARTING_RETAILER_CONFIRMATION,
  STARTING_DOWNPAYMENT,
  CUSTOMER_CONFIRMED,
  EVENTS_SCHEDULED,
  PARTNER_LOGO,
  SECRET_PASS,
  EXISTINGLOAN_STATUS,
};
