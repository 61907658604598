import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Typography from "./Typography";

const useStyles = createUseStyles((theme) => ({
  container: {
    // @ts-ignore
    paddingTop: theme?.padding,
    // @ts-ignore
    paddingBottom: theme?.padding,
  },
}));
const Heading = (props) => {
  const {
    title = "",
    overrideHeadingContainer = "",
    testID = "headingID",
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Typography
      type="h2"
      overrideClass={`${overrideHeadingContainer} ${classes.container}`}
      data-testid={testID}
      {...rest}
    >
      {title}
    </Typography>
  );
};

Heading.propTypes = {
  title: PropTypes.string,
  overrideHeadingContainer: PropTypes.string,
  testID: PropTypes.string,
};

export default Heading;
