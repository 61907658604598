import React from "react";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  imgStyle: {
    width: 7,
  },
});
const StraightLine = (props) => {
  const classes = useStyles();
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAAf0lEQVRoge3PMQoCUQwA0cFCT7EIC+KB3ONbiJewtbGwsYyDMg8+pEr+QJIpZ+AO3IDT1JHd1GLgAizAEdimjkwG7N/mw9SRyYCvKMBWgK0AWwG2AmwF2AqwFWArwFaArQBbAbYCbAXYCrAVYCvANhnw+DD/jBW4vt4q/yX5W0+6bAc1JXqQMgAAAABJRU5ErkJggg=="
      className={`${classes.imgStyle}`}
      {...props}
    />
  );
};

export default StraightLine;
