import React, { useEffect, useState, Suspense } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { getPathData } from "Utils/helpers";
import Flex from "./flex";
import Typography from "./Typography";
import Card from "./card";
import Loader from "./loader";
import Colors from "Global/colors";
const useStyles = createUseStyles((theme) => ({
  stepsMainContainer: {
    // @ts-ignore
    marginBottom: theme?.margin,
    overflow: "hidden",
    "&:hover": {
      overflowX: "auto",
    },
  },
  stepContainer: {
    // @ts-ignore
    padding: theme?.padding / 2,
    // @ts-ignore
    marginRight: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: Colors.GREY_30,
    },
  },
  stepBar: {
    // @ts-ignore
    padding: theme?.padding / 4,
    // @ts-ignore
    marginTop: theme?.margin,
    // @ts-ignore
    backgroundColor: (props) => props.unvisitedStepColor,
    // @ts-ignore
    borderRadius: theme?.borderRadius / 2,
    minWidth: 150,
  },
  numberIndicator: {
    width: 30,
    height: 30,
    // @ts-ignore
    padding: theme?.padding / 4,
    borderRadius: 20,
    // @ts-ignore
    marginRight: theme?.margin,
    // @ts-ignore
    backgroundColor: (props) => props.unvisitedStepColor,
  },
  activeStepClass: {
    // @ts-ignore
    backgroundColor: (props) => props.activeStepColor,
    color: Colors.WHITE,
  },
  previousStepClass: {
    // @ts-ignore
    backgroundColor: (props) => props.previousStepColor,
    color: Colors.WHITE,
  },
}));
const Stepper = (props) => {
  const {
    options = [],
    onSelectStep = null,
    step = 0,
    renderFallBack = null,
    activeStepColor = Colors.GREEN,
    previousStepColor = Colors.PRIMARY,
    unvisitedStepColor = Colors.GREY_20,
    unvisitedStepTextColor = Colors.GREY1,
    overrideStepMainContainer = "",
    overrideStepContainer = "",
    overrideStepBar = "",
    overrideNumberContainer = "",
    overrideContainer = "",
    overrideIndexClass = "",
    overrideLabelClass = "",
    stepClickable = false,
    overrideLabelStyle = {},
    testID = "stepperID",
    ...rest
  } = props;
  const [activeStep, setActiveStep] = useState(step);
  const classes = useStyles({
    ...props,
    activeStep,
    activeStepColor,
    previousStepColor,
    unvisitedStepColor,
    unvisitedStepTextColor,
  });
  const {
    stepsMainContainer,
    stepContainer,
    stepBar,
    numberIndicator,
    activeStepClass,
    previousStepClass,
  } = classes;
  const refs = options.reduce((acc, value, index) => {
    acc[index] = React.createRef();
    return acc;
  }, {});
  const onClickHandler = (tabNumber) => {
    if (onSelectStep) onSelectStep(tabNumber);
    if (tabNumber > activeStep) {
      if (refs[tabNumber + 3])
        refs[tabNumber + 3].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      else
        refs[options.length - 1].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    } else {
      if (refs[tabNumber - 2])
        refs[tabNumber - 2].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      else
        refs[0].current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  };
  const isPreviousStep = (key) => {
    return key < activeStep;
  };
  const isActiveStep = (key) => {
    return key === activeStep;
  };
  const getLabelColor = (key) => {
    if (isPreviousStep(key)) return previousStepColor;
    else if (isActiveStep(key)) return activeStepColor;
    else return unvisitedStepTextColor;
  };
  useEffect(() => {
    setActiveStep(step);
  }, [props.step]);
  const renderComponent = getPathData(options, [activeStep, "component"], null);
  return (
    <Flex direction="column" overrideClass={overrideContainer}>
      <Flex
        direction="row"
        overrideClass={`${stepsMainContainer} ${overrideStepMainContainer}`}
        data-testid={testID}
        {...rest}
      >
        {options.map((res, key) => {
          const isDisabled = getPathData(res, ["isDisabled"], false);
          return (
            <Flex
              key={key}
              overrideClass={`${stepContainer} ${overrideStepContainer}`}
              direction="column"
              justify="between"
              onClick={() => {
                if (stepClickable && !isDisabled) onClickHandler(key);
              }}
              overrideStyle={{ cursor: isDisabled ? "pointer" : "cursor" }}
              innerRef={refs[key]}
              data-testid={`stepper_${key}`}
            >
              <Flex align="center">
                <Flex
                  overrideClass={`${numberIndicator} ${
                    isPreviousStep(key) ? previousStepClass : ""
                  }
                  ${isActiveStep(key) ? activeStepClass : ""}
                  ${overrideNumberContainer}
                  `}
                  justify="center"
                  align="center"
                >
                  <Typography overrideClass={overrideIndexClass} type="h5">
                    {key + 1}
                  </Typography>
                </Flex>
                <Typography
                  color={getLabelColor(key)}
                  overrideClass={overrideLabelClass}
                  overrideStyle={overrideLabelStyle}
                  type="h5"
                >
                  {res?.label}
                </Typography>
              </Flex>
              <div
                className={`${stepBar} ${
                  isPreviousStep(key) ? previousStepClass : ""
                } ${isActiveStep(key) ? activeStepClass : ""}
                ${overrideStepBar}
                `}
              />
            </Flex>
          );
        })}
      </Flex>
      <Card>
        <Suspense fallback={renderFallBack ? renderFallBack() : <Loader />}>
          {renderComponent ? renderComponent() : null}
        </Suspense>
      </Card>
    </Flex>
  );
};

Stepper.propTypes = {
  options: PropTypes.array,
  overrideContainer: PropTypes.string,
  overrideStepMainContainer: PropTypes.string,
  overrideStepContainer: PropTypes.string,
  overrideLabelClass: PropTypes.string,
  overrideStepBar: PropTypes.string,
  overrideNumberContainer: PropTypes.string,
  overrideIndexClass: PropTypes.string,
  overrideLabelStyle: PropTypes.object,
  stepClickable: PropTypes.bool,
  testID: PropTypes.string,
  onSelectStep: PropTypes.func,
  renderFallBack: PropTypes.func,
};

export default Stepper;
