import React, { useReducer } from "react";
import { CommonAction } from "./actions";
export const StepperContext = React.createContext(null);
const StepperInitialState = {
  kyc_data: {},
  tranformed_kyc_data: {},
  eligibility_details: {},
  product_details: {
    originalProductList: [],
    categories: [],
    nestedProductData: {},
    productDetail: {
      productCategory: {
        extraData: [],
        label: null,
        value: null,
      },
      productSubCategory: "",
      searchProduct: "",
    },
    searchedItems: [],
    filteredList: [],
  },
  selectedProductData: {
    selected_variant: {},
    imei: "",
    imei2: "",
    serialNo: "",
    contract: "",
    nid_upload: "",
    nid_back: "",
    crif_report: "",
    downpayment_receipt: "",
    min_downpayment_amount_with_margin: 0,
    max_downpayment_amount_with_margin: 1,
    downpayment_amount: 0,
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case CommonAction.UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const RootContext = (props) => {
  const [stepperState, dispatch] = useReducer(reducer, StepperInitialState);
  const onChangeState = (payload) => {
    // @ts-ignore
    dispatch({
      type: CommonAction.UPDATE_STATE,
      payload: { ...payload },
    });
  };
  return (
    <StepperContext.Provider value={{ stepperState, onChangeState }}>
      {props.children}
    </StepperContext.Provider>
  );
};

export default RootContext;
