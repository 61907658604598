import { getPathData } from "Utils/helpers";
import PartnerConfig from "partner_configuration";
const PARSED_URL = getPathData(window, ["location", "hostname"], "")
  .replaceAll("-", "_")
  .replaceAll(".", "_");

let PARTNER_CODE = PARSED_URL;
export const PARTNER_CODE_URL = PARSED_URL;
let APIS_URL = null;
let KYC_BASE_URL = null;
const MODE = process.env.REACT_APP_MODE;
let CLIENT_SECRET = "";
let PACKAGEID = "";
let OS_CODE = "";
let KYCID = process.env.REACT_APP_KYCID;
let XTENANTID = process.env.REACT_APP_XTENANTID;
let PRODUCT_TYPE = "";
let APP_TYPE = "default";
let REDIRECT_URL = "";
if (MODE === "development") {
  const dev_config = PartnerConfig.development_version();
  APIS_URL = getPathData(dev_config, ["baseurl"], "");
  PARTNER_CODE = getPathData(dev_config, ["partner_code"], "");
  CLIENT_SECRET = getPathData(dev_config, ["client_secret"], "");
  PACKAGEID = getPathData(dev_config, ["package_id"], "");
  OS_CODE = getPathData(dev_config, ["os_code"], "");
  PRODUCT_TYPE = getPathData(dev_config, ["product_type"], "");
  APP_TYPE = getPathData(dev_config, ["app_type"], "default");
  KYC_BASE_URL = getPathData(dev_config, ["kyc_base_url"], "");
  REDIRECT_URL = getPathData(dev_config, ["redirect_url"], "");
} else if (MODE === "staging") {
  const staging_config = PartnerConfig.staging_version(
    PARTNER_CODE.toUpperCase()
  );
  APIS_URL = getPathData(staging_config, ["baseurl"], "");
  CLIENT_SECRET = getPathData(staging_config, ["client_secret"], "");
  PACKAGEID = getPathData(staging_config, ["package_id"], "");
  OS_CODE = getPathData(staging_config, ["os_code"], "");
  KYCID = getPathData(staging_config, ["kyc_id"], "");
  XTENANTID = getPathData(staging_config, ["xtenant_id"], "");
  PARTNER_CODE = getPathData(staging_config, ["partner_code"], "");
  PRODUCT_TYPE = getPathData(staging_config, ["product_type"], "");
  APP_TYPE = getPathData(staging_config, ["app_type"], "default");
  KYC_BASE_URL = getPathData(staging_config, ["kyc_base_url"], "");
  REDIRECT_URL = getPathData(staging_config, ["redirect_url"], "");
} else if (MODE === "production" || MODE === "pre_production") {
  const prod_config = PartnerConfig.production_version(
    PARTNER_CODE.toUpperCase()
  );
  APIS_URL = getPathData(prod_config, ["baseurl"], "");
  CLIENT_SECRET = getPathData(prod_config, ["client_secret"], "");
  PACKAGEID = getPathData(prod_config, ["package_id"], "");
  OS_CODE = getPathData(prod_config, ["os_code"], "");
  KYCID = getPathData(prod_config, ["kyc_id"], "");
  XTENANTID = getPathData(prod_config, ["xtenant_id"], "");
  PARTNER_CODE = getPathData(prod_config, ["partner_code"], "");
  PRODUCT_TYPE = getPathData(prod_config, ["product_type"], "");
  APP_TYPE = getPathData(prod_config, ["app_type"], "default");
  KYC_BASE_URL = getPathData(prod_config, ["kyc_base_url"], "");
  REDIRECT_URL = getPathData(prod_config, ["redirect_url"], "");
}
const BASEURL = APIS_URL;
const PRODUCTURL =
  PRODUCT_TYPE?.toLowerCase() === "handset" ? "handsets" : "installments";
const MOCKGETSTATUS = BASEURL + `/${PRODUCTURL}/eligibility`;
const MOCKCUSTOMERKYC = KYC_BASE_URL + `/kyc2/kycResponseBody`;
const MOCKCUSTOMERKYCDEFINITION = KYC_BASE_URL + `/kyc2/get`;
const MOCKFETCHMETA = BASEURL + `/partners/metadata`;
const MOCKGETJORDANSTATUS = BASEURL + `/${PRODUCTURL}/eligibility`;
const MOCKIPADDRESS = "https://api.ipify.org";
const GETSTATUS = (msisdn) =>
  `${BASEURL}/${PRODUCTURL}/eligibility?msisdn=${msisdn}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&run_product_offer=false&with_device_details=true`;
const DISBURSE = BASEURL + `/${PRODUCTURL}/disburse`;
const CHECKEMI = BASEURL + `/${PRODUCTURL}/check_imei`;
const SENDOTP = BASEURL + "/otps/generate";
const RESENDOTP = BASEURL + "/otps/resend";
const DOWNPAYMENT = BASEURL + `/${PRODUCTURL}/downpayment`;
const UPDATE_IMEI = BASEURL + `/${PRODUCTURL}/update_imei`;
const CHECK_ACTIVATION = BASEURL + `/${PRODUCTURL}/activation`;
const RETAILER_CONFIRMATION = BASEURL + `/${PRODUCTURL}/retailer_confirmation`;
const REGISTRATION = BASEURL + `/${PRODUCTURL}/registration`;
const CHECK_DEVICE_REGISTRATION =
  BASEURL + `/${PRODUCTURL}/check_device_registration`;
const VERIFY_OTP = BASEURL + "/otps/authenciate_otp";
const CUSTOMER_SCORE = BASEURL + `/${PRODUCTURL}/get_creditscore`;
const GET_CUSTOMER_KYC_DEFINITION = KYC_BASE_URL + `/kyc2/get?id=${KYCID}`;
const CREATE_KYC_USER = KYC_BASE_URL + "/kyc2/kycResponse";
const GET_CUSTOMER_KYC = (msisdn) =>
  `${KYC_BASE_URL}/kyc2/kycResponseBody?msisdn=${msisdn}&kycId=${KYCID}&fieldIds=true`;
const GET_REFRESH_TOKEN = BASEURL + "/auth/token";
const UPDATE_CUSTOMER_CONSENT =
  BASEURL + `/${PRODUCTURL}/update_customer_consent`;
const UPLOAD_KYC_DOCUMENT = KYC_BASE_URL + "/kyc2/upload";
const GET_UPLOAD_DOCUMENT = KYC_BASE_URL + "/kyc2/getDocument";
const FETCH_META = BASEURL + `/partners/metadata?partner_code=${PARTNER_CODE}`;
const LOGIN = BASEURL + "/auth/login";
const RESETPASSWORD = BASEURL + "/user/forgot_password";
const GET_JORDAN_CUSTOMER_KYC = (nid) =>
  `${KYC_BASE_URL}/kyc2/kycResponseBody?&kycId=${KYCID}&fieldIds=true&responseId=${nid}`;
// const JORDAN_MOCK_BASE_URL =
//   "https://7bb70618-2aed-406e-ba60-28998815ac7c.mock.pstmn.io/apis/v1";
// const VALIDATE_JORDAN_NID = BASEURL + `/${PRODUCTURL}/customers/verification`;
const VALIDATE_JORDAN_NID = BASEURL + `/${PRODUCTURL}/customer_verification`;
const FETCH_JORDAN_DEVICE_LIST = BASEURL + `/${PRODUCTURL}/devices`;
const FETCH_JORDAN_DOWNPAYMENT_BOUNDARIES =
  BASEURL + `/${PRODUCTURL}/downpayment_details`;
const FETCH_JORDAN_EMI_VARIANTS = BASEURL + `/${PRODUCTURL}/eligible_variants`;
const INITIATE_JORDAN_LOAN = BASEURL + `/${PRODUCTURL}/initiate_loan`;
const JORDAN_DOWNPAYMENT = BASEURL + `/${PRODUCTURL}/downpayment_confirmation`;
const DISBURSE_JORDAN_LOAN = BASEURL + `/${PRODUCTURL}/disbursement`;
const GET_JORDAN_STATUS = (msisdn) =>
  `${BASEURL}/${PRODUCTURL}/eligibility?msisdn=${msisdn}&product_type=${PRODUCT_TYPE}&partner_code=${PARTNER_CODE}&run_product_offer=true&with_device_details=true`;
const GET_PAYMENT_OPTIONS = `${BASEURL}/${PRODUCTURL}/payment_details`;
const INITIATE_LOAN_JOURNEY = `${BASEURL}/${PRODUCTURL}/initiate_loan_application`;
const GET_LOAN_DETAILS = `${BASEURL}/${PRODUCTURL}/get_detail`;
const JORDAN_DEVICE_NO_VALIDATE = `${BASEURL}/${PRODUCTURL}/device_no_validate`;
const FETCH_STORE_LIST = BASEURL + `/${PRODUCTURL}/retailer_store_details`;
const FETCH_REPORT_LIST = BASEURL + `/${PRODUCTURL}/loan_details`;
const DOWNLOAD_REPORT = BASEURL + "/reports";
const IP_ADDRESS = "https://api.ipify.org";
const UPDATE_STORE_SELECTION = BASEURL + `/${PRODUCTURL}/update_store_details`;
export {
  BASEURL,
  KYCID,
  GETSTATUS,
  DISBURSE,
  CHECKEMI,
  SENDOTP,
  DOWNPAYMENT,
  UPDATE_IMEI,
  RESENDOTP,
  CHECK_ACTIVATION,
  RETAILER_CONFIRMATION,
  CHECK_DEVICE_REGISTRATION,
  REGISTRATION,
  GET_CUSTOMER_KYC,
  VERIFY_OTP,
  CUSTOMER_SCORE,
  GET_CUSTOMER_KYC_DEFINITION,
  GET_REFRESH_TOKEN,
  UPDATE_CUSTOMER_CONSENT,
  CREATE_KYC_USER,
  UPLOAD_KYC_DOCUMENT,
  GET_UPLOAD_DOCUMENT,
  FETCH_META,
  LOGIN,
  PARTNER_CODE,
  CLIENT_SECRET,
  PACKAGEID,
  OS_CODE,
  XTENANTID,
  RESETPASSWORD,
  PRODUCT_TYPE,
  MOCKGETSTATUS,
  MOCKCUSTOMERKYC,
  MOCKCUSTOMERKYCDEFINITION,
  APP_TYPE,
  GET_JORDAN_CUSTOMER_KYC,
  VALIDATE_JORDAN_NID,
  FETCH_JORDAN_DEVICE_LIST,
  FETCH_JORDAN_DOWNPAYMENT_BOUNDARIES,
  FETCH_JORDAN_EMI_VARIANTS,
  INITIATE_JORDAN_LOAN,
  JORDAN_DOWNPAYMENT,
  DISBURSE_JORDAN_LOAN,
  GET_JORDAN_STATUS,
  FETCH_STORE_LIST,
  GET_PAYMENT_OPTIONS,
  INITIATE_LOAN_JOURNEY,
  GET_LOAN_DETAILS,
  JORDAN_DEVICE_NO_VALIDATE,
  REDIRECT_URL,
  MOCKFETCHMETA,
  MOCKGETJORDANSTATUS,
  IP_ADDRESS,
  MOCKIPADDRESS,
  UPDATE_STORE_SELECTION,
  DOWNLOAD_REPORT,
  FETCH_REPORT_LIST,
};
