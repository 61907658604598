import React from "react";
// import { createUseStyles } from "react-jss";
// const useStyles = createUseStyles(() => ({
//   imgStyle: {
//     width: 15,
//     height: 15,
//   },
// }));
const DownAngleArrow = (props) => {
  const { overrideStyle = {}, overrideClass = "", ...rest } = props;
  return (
    <img
      src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDk2IDk2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PHBhdGggZD0iTTgxLjg0NTcsMjUuMzg3NmE2LjAyMzksNi4wMjM5LDAsMCwwLTguNDUuNzY3Nkw0OCw1Ni42MjU3bC0yNS4zOTYtMzAuNDdhNS45OTksNS45OTksMCwxLDAtOS4yMTE0LDcuNjg3OUw0My4zOTQzLDY5Ljg0NTJhNS45OTY5LDUuOTk2OSwwLDAsMCw5LjIxMTQsMEw4Mi42MDc0LDMzLjg0MzFBNi4wMDc2LDYuMDA3NiwwLDAsMCw4MS44NDU3LDI1LjM4NzZaIi8+PC9zdmc+"
      style={{ ...overrideStyle }}
      className={`${overrideClass}`}
      alt="download-arrow-icon"
      {...rest}
    />
  );
};

export default DownAngleArrow;
