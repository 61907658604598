export default {
  development_version: () => {
    return {
      baseurl: process.env.REACT_APP_API_URL,
      package_id: process.env.REACT_APP_PACKAGEID,
      partner_code: process.env.REACT_APP_PARTNERCODE,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      os_code: process.env.REACT_APP_OS,
      product_type: process.env.REACT_APP_PRODUCT_TYPE,
      app_type: process.env.REACT_APP_TYPE,
      kyc_base_url: process.env.REACT_APP_KYC_BASE_URL,
      redirect_url: process.env.REACT_APP_REDIRECT_URL,
    };
  },
  staging_version: (partner) => {
    return {
      baseurl: process.env[`REACT_APP_API_URL_${partner}`],
      package_id: process.env[`REACT_APP_PACKAGEID_${partner}`],
      partner_code: process.env[`REACT_APP_PARTNERCODE_${partner}`],
      client_secret: process.env[`REACT_APP_CLIENT_SECRET_${partner}`],
      os_code: process.env[`REACT_APP_OS_${partner}`],
      kyc_id: process.env[`REACT_APP_KYCID_${partner}`],
      xtenant_id: process.env[`REACT_APP_XTENANTID_${partner}`],
      product_type: process.env[`REACT_APP_PRODUCT_TYPE_${partner}`],
      app_type: process.env[`REACT_APP_TYPE_${partner}`],
      kyc_base_url: process.env[`REACT_APP_KYC_BASE_URL_${partner}`],
      redirect_url: process.env[`REACT_APP_REDIRECT_URL_${partner}`],
    };
  },
  production_version: (partner) => {
    return {
      baseurl: process.env[`REACT_APP_API_URL_${partner}`],
      package_id: process.env[`REACT_APP_PACKAGEID_${partner}`],
      partner_code: process.env[`REACT_APP_PARTNERCODE_${partner}`],
      client_secret: process.env[`REACT_APP_CLIENT_SECRET_${partner}`],
      os_code: process.env[`REACT_APP_OS_${partner}`],
      kyc_id: process.env[`REACT_APP_KYCID_${partner}`],
      xtenant_id: process.env[`REACT_APP_XTENANTID_${partner}`],
      product_type: process.env[`REACT_APP_PRODUCT_TYPE_${partner}`],
      app_type: process.env[`REACT_APP_TYPE_${partner}`],
      kyc_base_url: process.env[`REACT_APP_KYC_BASE_URL_${partner}`],
      redirect_url: process.env[`REACT_APP_REDIRECT_URL_${partner}`],
    };
  },
};
