import React from "react";
const HometIcon = (props) => {
  const { color = "#FFFFFF" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M6 25.3333V13.5385C6 13.1568 6.08537 12.7953 6.2561 12.4539C6.42683 12.1124 6.66283 11.8312 6.9641 11.6103L14.5538 5.89237C14.975 5.57099 15.4564 5.4103 15.9979 5.4103C16.5394 5.4103 17.0222 5.57099 17.4461 5.89237L25.0358 11.6103C25.3371 11.8312 25.5731 12.1124 25.7438 12.4539C25.9146 12.7953 25.9999 13.1568 25.9999 13.5385V25.3333C25.9999 25.8786 25.8029 26.3483 25.4089 26.7423C25.0149 27.1363 24.5453 27.3333 24 27.3333H19.7435C19.4021 27.3333 19.1159 27.2178 18.8849 26.9868C18.6539 26.7558 18.5384 26.4696 18.5384 26.1282V19.6154C18.5384 19.2739 18.4229 18.9877 18.1919 18.7567C17.961 18.5257 17.6748 18.4102 17.3333 18.4102H14.6666C14.3252 18.4102 14.039 18.5257 13.808 18.7567C13.577 18.9877 13.4615 19.2739 13.4615 19.6154V26.1282C13.4615 26.4696 13.346 26.7558 13.115 26.9868C12.8841 27.2178 12.5978 27.3333 12.2564 27.3333H7.99997C7.45468 27.3333 6.98503 27.1363 6.59103 26.7423C6.19701 26.3483 6 25.8786 6 25.3333Z"
        fill={color}
        {...props}
      />
    </svg>
  );
};

export default HometIcon;
