import React from "react";
const StepSuccessIcon = (props) => {
  const { color = "#0C9D4B" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.5808 14.1462L8.25765 11.8231C8.1192 11.6846 7.94517 11.6138 7.73555 11.6106C7.52594 11.6074 7.34869 11.6782 7.20383 11.8231C7.05896 11.9679 6.98653 12.1436 6.98653 12.35C6.98653 12.5564 7.05896 12.732 7.20383 12.8769L9.94805 15.6211C10.1288 15.8019 10.3397 15.8923 10.5808 15.8923C10.8218 15.8923 11.0327 15.8019 11.2134 15.6211L16.7769 10.0577C16.9154 9.91921 16.9862 9.74517 16.9894 9.53555C16.9926 9.32594 16.9218 9.1487 16.7769 9.00383C16.632 8.85896 16.4564 8.78653 16.25 8.78653C16.0436 8.78653 15.868 8.85896 15.7231 9.00383L10.5808 14.1462ZM12.0017 21.5C10.6877 21.5 9.45269 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45269 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.4496 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2528 7.13835 20.7516 8.29398C21.2505 9.4496 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2528 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0017 21.5Z"
        fill={color}
        {...props}
      />
    </svg>
  );
};

export default StepSuccessIcon;
