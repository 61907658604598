import React, { useEffect, useState, Suspense, lazy } from "react";
import { TextInput, Flex, TextArea, Toast, Slider, SelectBox } from "Component";
import { RetailerJordanColors } from "Global/colors";
import { getPathData } from "Utils/helpers";
import { useTranslation } from "react-i18next";
// import SelectBox from "Component/selectBox";
import {
  KYCID,
  UPLOAD_KYC_DOCUMENT,
  GET_UPLOAD_DOCUMENT,
  XTENANTID,
} from "HttpRequest/url";
import { Request } from "HttpRequest";
// import { DecryptData } from "Global/helper";
import JordanLoader from "../jordanLoader";
import { createUseStyles } from "react-jss";
import Theme from "Global/retailerJordanTheme";
import Cookies from "js-cookie";
const FileUploader = lazy(() => import("./dynamicFileUpload"));
const { toast } = Toast;
const useStyles = createUseStyles({
  overrideInputClass: {
    minHeight: 44,
    fontSize: 14,
    borderRadius: 4,
    fontFamily: "PlusJakartaSans-Regular",
    "& ::placeholder": {
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Medium",
      color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
    },
  },
  selectBoxContainerClass: {
    margin: 2,
  },
  overrideOptionClass: {
    padding: "8px 10px",
    "& div": {
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Regular",
      color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
    },
  },
  overrideOptionContClass: {
    borderRadius: "2px",
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.06)",
  },
  fileUploadContainer: {
    padding: Theme.padding + 4,
    borderRadius: Theme.borderRadius,
    boxShadow: "none",
    border: `1px dashed ${RetailerJordanColors.FILEUPLOADBORDER}`,
    background: RetailerJordanColors.WHITE,
    marginTop: Theme?.margin,
    height: 128,
  },
});
const RenderingForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const KYC_TOKEN = `Bearer ${Cookies.get("access_token")}`;

  const { options = [], exclude = [], formDataHandler = {} } = props;
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = formDataHandler;
  const [formFields, setFormFields] = useState([]);
  const [temporaryState, setTemporaryState] = useState({});
  const ComponentList = {
    TEXT: (params) => {
      const fieldName = getPathData(params, ["fieldName"], "fieldName");
      const handleChange = (resp, extraParams) => {
        const isError = getPathData(extraParams, ["error"], false);
        if (!isError) onChangeHandler(resp, { fieldName });
      };
      const handleTextAreaChange = (resp) => {
        onChangeHandler(resp, { fieldName });
      };
      const type = getPathData(params, ["rest", "displayType"], "text");
      if (type === "MULTILINE")
        return <TextArea {...params} handleChange={handleTextAreaChange} />;
      return (
        <TextInput
          {...params}
          type="text"
          handleChange={handleChange}
          inputClass={classes.overrideInputClass}
          errorTextColor={RetailerJordanColors.ERROR_RED}
        />
      );
    },
    NUMBER: (params) => {
      const fieldName = getPathData(params, ["fieldName"], "fieldName");
      const handleChange = (resp, extraParams) => {
        const isError = getPathData(extraParams, ["error"], false);
        if (!isError) onChangeHandler(resp, { fieldName });
      };
      const type = getPathData(params, ["rest", "displayType"], "text");
      if (type === "SLIDER") return <Slider {...params} />;
      return (
        <TextInput
          {...params}
          format="number"
          handleChange={handleChange}
          inputClass={classes.overrideInputClass}
          errorTextColor={RetailerJordanColors.ERROR_RED}
        />
      );
    },
    CALENDAR: (params) => {
      const fieldName = getPathData(params, ["fieldName"], "fieldName");
      const handleChange = (resp, extraParams) => {
        const isError = getPathData(extraParams, ["error"], false);
        if (!isError) onChangeHandler(resp, { fieldName });
      };
      return (
        <TextInput
          {...params}
          type="date"
          minDate=""
          handleChange={handleChange}
          inputClass={classes.overrideInputClass}
          errorTextColor={RetailerJordanColors.ERROR_RED}
        />
      );
    },
    RADIO: (params) => {
      const fieldName = getPathData(params, ["fieldName"], "fieldName");
      return (
        <SelectBox
          {...params}
          labelKey="display"
          valueKey="value"
          handleChange={(resp) => {
            onChangeHandler(getPathData(resp, ["res", 0], ""), { fieldName });
          }}
          inputType="single_value"
          responseType="single_value"
          placeholder="Select"
          overrideMainContainerClass={classes.overrideInputClass}
          containerClass={classes.selectBoxContainerClass}
          overrideOptionClass={classes.overrideOptionClass}
          overrideOptionContClass={classes.overrideOptionContClass}
        />
      );
    },
    DOCUMENT: (params) => {
      const fieldName = getPathData(params, ["fieldName"], "");
      const fieldKeyName = getPathData(params, ["fieldKeyName"], "");
      const temporaryState = getPathData(
        params,
        ["temporaryState", fieldKeyName],
        !params?.value
          ? ""
          : `${GET_UPLOAD_DOCUMENT}?fileName=${params?.value}&Authorization=${KYC_TOKEN}`
      );
      const handleTemporaryState = getPathData(
        params,
        ["handleTemporaryState"],
        null
      );
      const accept = getPathData(
        params,
        ["rest", "fileTypes"],
        [".jpg", ".jpeg", ".png"]
      );
      const uploadHandler = ({ imageData, setIsLoading }) => {
        setIsLoading({ loader: true, msg: `${t("upload_file_loader_msg")}` });
        const fileExtension = getPathData(imageData, ["name"], "").split(".");
        if (
          !accept.includes(
            `.${getPathData(
              fileExtension,
              [fileExtension.length - 1],
              ""
            ).toLowerCase()}`
          )
        ) {
          toast.error(`${t("invalid_file_format")}`);
          setIsLoading((prev) => ({ ...prev, loader: false }));
          setFieldValue(fieldName, null);
          handleTemporaryState(fieldKeyName, null);
          return;
        }
        Request({
          method: "POST",
          url: UPLOAD_KYC_DOCUMENT,
          payload: {
            file: imageData,
            kycId: KYCID,
            fieldId: fieldName,
          },
          defaultHeaders: false,
          failureRedirection: true,
          headers: {
            "x-tenant-id": XTENANTID,
            "Content-Type": "multipart/form-data",
          },
          successAction: (res) => {
            const fileName = getPathData(res, ["data", "data", "fileName"], "");
            setFieldValue(fieldName, fileName);
            handleTemporaryState(fieldKeyName, imageData);
            setIsLoading((prev) => ({ ...prev, loader: false }));
          },
          failureAction: () => {
            let message = "KYC";
            toast.error(message + `${t("doc_submit_failed")}`);
            setIsLoading((prev) => ({ ...prev, loader: false }));
          },
        });
      };
      return (
        <FileUploader
          {...params}
          value={temporaryState}
          showLabel={params?.showLabel}
          keyName={fieldName}
          accept={accept.join(",")}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          handleUploadFile={uploadHandler}
          isUploadDisabled={params?.value}
          overrideUploadContainer={classes.fileUploadContainer}
          previewTitle={t(`${params?.fieldKeyName}_preview_title`)}
          uploadedUrl={
            params?.value
              ? `${GET_UPLOAD_DOCUMENT}?fileName=${params?.value}&Authorization=${KYC_TOKEN}`
              : null
          }
          imageChangeHandler={(res) => {
            if (
              fieldKeyName === "document_front" &&
              res?.name ===
                getPathData(
                  params,
                  ["temporaryState", "document_back", "name"],
                  null
                )
            ) {
              toast.error(`${t("both_doc_different")}`);
              setFieldValue(fieldName, null);
              handleTemporaryState(fieldKeyName, null);
              return;
            } else if (
              fieldKeyName === "document_back" &&
              res?.name ===
                getPathData(
                  params,
                  ["temporaryState", "document_front", "name"],
                  null
                )
            ) {
              toast.error(`${t("both_doc_different")}`);
              setFieldValue(fieldName, null);
              handleTemporaryState(fieldKeyName, null);
              return;
            } else {
              setFieldValue(fieldName, null);
              handleTemporaryState(fieldKeyName, res);
            }
          }}
        />
      );
    },
  };
  useEffect(() => {
    options.sort((a, b) => {
      return a?.displayOrder - b?.displayOrder;
    });
    setFormFields(options);
  }, [props.options]);
  const onChangeHandler = (data = "", currentFieldParams = {}) => {
    const fieldName = getPathData(currentFieldParams, ["fieldName"], null);
    fieldName && setFieldValue(fieldName, data);
  };
  const handleTemporaryState = (key, value) => {
    setTemporaryState((prev) => ({ ...prev, [key]: value }));
  };
  return formFields.map((res, key) => {
    const component = getPathData(
      ComponentList,
      [getPathData(res, ["type"], "")],
      null
    );
    let params = {};
    const {
      editable = false,
      helpText = "",
      mandatory = "",
      id = "",
      options = [],
      validation = null,
      fieldName = "",
      overrideFormContainer = "",
      showLabel = true,
      ...rest
    } = res;
    if (component && !exclude.includes(id)) {
      const isTouched = getPathData(touched, [id], null);
      const isError = getPathData(errors, [id], null);
      params = {
        ...rest,
        showLabel,
        handleTemporaryState,
        temporaryState,
        disabled: !editable,
        label: t(fieldName),
        required: mandatory,
        helpText,
        value: getPathData(values, [id], ""),
        touched: isTouched,
        error: isError && isTouched,
        errorMsg: isError,
        options,
        fieldName: id,
        name: id,
        fieldKeyName: fieldName,
        regexExp: validation ? [new RegExp(validation)] : [],
        handleChange: (resp) => onChangeHandler(resp, res),
        overrideMainContainerStyle: {
          backgroundColor: RetailerJordanColors.WHITE,
        },
        helpTextColor: RetailerJordanColors.ORANGE,
        onBlur: handleBlur,
      };
      return (
        component && (
          <Suspense fallback={<JordanLoader label="Loading..." />}>
            <Flex key={key} overrideClass={overrideFormContainer}>
              {component(params)}
            </Flex>
          </Suspense>
        )
      );
    }
  });
};

const DynamicForm = (props) => {
  const { renderFormView, ...extra } = props;
  const onSubmit = () => {};
  return renderFormView && renderFormView({ RenderingForm, onSubmit, extra });
};
export default DynamicForm;
