import React from "react";
import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  imgStyle: {
    width: 7,
  },
});
const EditIcon = (props) => {
  const classes = useStyles();
  return (
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbElEQVR4nO3SsQ2AMAxE0b8AM8EqiEFwyQyZDUZAjOAUhAYRKGJT+aRr30mWIXJmAjbgABLQYRgB9NbkPbBbwP3LyNqKS4GkMjJa4FoZEUtcS5cW9Au/OuCIz4FrnOWXb8EbxxvHG8cbj/CUDMTueTOAku8eAAAAAElFTkSuQmCC"
      className={`${classes.imgStyle}`}
      alt="edit-icon"
      {...props}
    />
  );
};

export default EditIcon;
