import React from "react";
const CloseIcon = (props) => {
  return (
    <img
      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMiAwYzYuNjIzIDAgMTIgNS4zNzcgMTIgMTJzLTUuMzc3IDEyLTEyIDEyLTEyLTUuMzc3LTEyLTEyIDUuMzc3LTEyIDEyLTEyem0wIDFjNi4wNzEgMCAxMSA0LjkyOSAxMSAxMXMtNC45MjkgMTEtMTEgMTEtMTEtNC45MjktMTEtMTEgNC45MjktMTEgMTEtMTF6bTAgMTAuMjkzbDUuMjkzLTUuMjkzLjcwNy43MDctNS4yOTMgNS4yOTMgNS4yOTMgNS4yOTMtLjcwNy43MDctNS4yOTMtNS4yOTMtNS4yOTMgNS4yOTMtLjcwNy0uNzA3IDUuMjkzLTUuMjkzLTUuMjkzLTUuMjkzLjcwNy0uNzA3IDUuMjkzIDUuMjkzeiIvPjwvc3ZnPg=="
      {...props}
    />
  );
};

export default CloseIcon;
