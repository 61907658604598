import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Theme from "Global/oldRetailerTheme";
import Flex from "./flex";
import TextInput from "./textInput";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  otpBoxStyle: {
    height: 60,
    fontSize: 25,
    textAlign: "center",
  },
  otpContainerClass: {
    padding: Theme.padding / 5,
  },
});

const OTP = (props) => {
  const classes = useStyles();
  const {
    boxNumber = 6,
    onChange = null,
    overrideClassContainer = "",
    overrideContainerStyle = {},
    inputProps = {},
    defaultValue = [],
    overrideInputBoxStyle = "",
    testID = "otpID",
    otpInputBoxStyle = {},
    overrideInputBoxContClass = "",
    overrideInputBoxContStyle = {},
    inputBoxContainerStyle = {},
  } = props;
  const [otpOption, setOtpOptions] = useState(defaultValue);

  const refObj = Array(boxNumber)
    .fill(0)
    .map(() => useRef(null));
  useEffect(() => {
    refObj[0]?.current.focus();
  }, []);
  useEffect(() => {
    setOtpOptions(defaultValue);
  }, [props.defaultValue]);
  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      return false;
    }
  };
  const inputfocus = (elmnt) => {
    const otpOptionCpy = [...otpOption];
    const numbers = /^\d+$/;
    let inputData = elmnt.target.value;
    if (inputData == "" && otpOption.length === 0) {
      return false;
    } else if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex;
      if (next >= 0) {
        // if (next === 0) {
        //   setOtpOptions([]);
        //   return;
        // }
        if (inputData === "") {
          refObj[next - 1]?.current.focus();
        } else {
          setOtpOptions(
            otpOptionCpy.map((res, index) => {
              if (index === next) return "";
              return res;
            })
          );
        }
      }
    } else if (inputData !== "") {
      if (inputData === "" || inputData.length > 1) {
        return;
      }
      if (!inputData.match(numbers)) {
        return;
      }
      const next = elmnt.target.tabIndex;
      if (next !== otpOption.length) {
        otpOptionCpy[next] = inputData;
        setOtpOptions(otpOptionCpy);
        refObj[next + 1]?.current.focus();
        return;
      }
      if (next <= boxNumber && otpOption.length <= boxNumber) {
        otpOptionCpy.push(inputData);
        setOtpOptions(otpOptionCpy);
        refObj[next + 1]?.current.focus();
      }
    }
  };
  const onFocus = (e) => {
    const tabIndex = e.target.tabIndex;
    e.preventDefault();
    if (tabIndex + 1 < otpOption.length && otpOption.length <= boxNumber) {
      return;
    }
  };
  useEffect(() => {
    if (onChange) onChange(otpOption);
  }, [otpOption]);
  return (
    <Flex
      justify="between"
      align="center"
      overrideClass={overrideClassContainer}
      overrideStyle={overrideContainerStyle}
      data-testid={testID}
    >
      {refObj.map((res, key) => {
        return (
          <TextInput
            value={otpOption[key]}
            overrideRef={res}
            key={key}
            maxLength={1}
            format="number"
            tabIndex={key}
            onKeyUp={inputfocus}
            onKeyDown={(e) => onKeyDown(e)}
            onFocus={onFocus}
            autoComplete="off"
            inputBoxStyle={{ ...otpInputBoxStyle }}
            containerStyle={{
              padding: Theme.padding / 5,
              ...overrideInputBoxContStyle,
            }}
            containerClass={`${classes.otpContainerClass} ${overrideInputBoxContClass}`}
            inputClass={`${classes.otpBoxStyle}${overrideInputBoxStyle}`}
            overrideInputBoxConatiner={{ ...inputBoxContainerStyle }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            data-testid={`otp_${key}`}
            {...inputProps}
          />
        );
      })}
    </Flex>
  );
};

OTP.propTypes = {
  boxNumber: PropTypes.number,
  overrideClassContainer: PropTypes.string,
  overrideContainerStyle: PropTypes.object,
  inputProps: PropTypes.object,
  defaultValue: PropTypes.array,
  testID: PropTypes.string,
  onChange: PropTypes.func,
  format: PropTypes.oneOf(["number", "string"]),
  maxLength: PropTypes.number,
  overrideInputBoxStyle: PropTypes.string,
  otpInputBoxStyle: PropTypes.object,
  overrideInputBoxContClass: PropTypes.string,
  overrideInputBoxContStyle: PropTypes.object,
  inputBoxContainerStyle: PropTypes.object,
};

export default OTP;
