import React from "react";
import PropTypes from "prop-types";
import Flex from "./flex";
import Typography from "./Typography";
import { createUseStyles } from "react-jss";
import { getPathData } from "Utils/helpers";
import Colors from "Global/colors";
const useStyles = createUseStyles((theme) => {
  return {
    typographyClass: () => ({
      ...getPathData(theme, ["typography", "label"], {}),
    }),
    labelContClass: {
      display: "block",
      // @ts-ignore
      marginBottom: theme.margin / 2,
    },
  };
});
const Label = (props) => {
  const {
    label = "",
    labelStyle = {},
    required = false,
    overrideLabelContClass = "",
    overrideLabelClass = "",
    labelColour = "",
    testID = "labelID",
  } = props;
  const classes = useStyles();
  return (
    <Flex
      overrideClass={`${classes.labelContClass} ${overrideLabelContClass}`}
      overrideStyle={{ ...labelStyle }}
    >
      {!required ? (
        <Typography
          overrideClass={`${classes.typographyClass} ${overrideLabelClass}`}
          color={Colors.LABEL_COLOR}
          data-testid={testID}
        >
          {label}
        </Typography>
      ) : (
        <Typography
          overrideClass={`${classes.typographyClass} ${overrideLabelClass}`}
          color={labelColour ? labelColour : Colors.LABEL_COLOR}
          data-testid={testID}
        >
          {label}
          <span style={{ color: Colors.RED }}> *</span>
        </Typography>
      )}
    </Flex>
  );
};
Label.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  labelStyle: PropTypes.object,
  overrideLabelContClass: PropTypes.string,
  name: PropTypes.string,
  testID: PropTypes.string,
  overrideLabelClass: PropTypes.string,
  labelColour: PropTypes.string,
};
export default Label;
