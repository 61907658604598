import React, { useState, useEffect, useRef } from "react";
import { createUseStyles } from "react-jss";
import { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";
import { Flex } from "Component";
import PropTypes from "prop-types";

const useStyles = createUseStyles({
  popOverWrapper: {
    position: "relative",
    width: "100%",
    "& div": {
      background: "#fff",
      width: "100%",
      borderRadius: "4px",
      border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
      cursor: "pointer",
      "& label": {
        ...Theme.typography.h5,
        cursor: "pointer",
      },
    },
  },
  popover: {
    position: "absolute",
    background: "#FFF",
    top: "40px",
    left: "0",
    width: "100%",
    "& ul": {
      listStyle: "none",
      border: `1px solid ${RetailerJordanColors.BORDERCOLOR_10}`,
      margin: "0",
      paddingLeft: "0",
      "& li": {
        padding: "10px 15px",
        ...Theme.typography.h5,
        cursor: "pointer",
        width: "initial",
        "&:hover": {
          background: RetailerJordanColors.GREY25,
        },
      },
    },
  },
  rotate: {
    transform: "rotate(180deg)",
  },
  disabledClass: {
    cursor: "not-allowed !important",
    color: `${RetailerJordanColors.PRIMARY}!important`,
    opacity: "0.5",
    "& hover": {
      background: "#FFF",
    },
  },
  disabledContainerClass: {
    backgroundColor: RetailerJordanColors?.DISABLED,
  },
});

const PopOver = ({
  options,
  value,
  handleChange,
  overridePopoverWrapper = "",
  overridePopover = "",
  disabled = false,
  labelKey = "label",
  valueKey = "value",
  selectedLabelKey = "label",
  testID = "popover_id",
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    handleChange(option);
    setIsOpen(false);
  };

  return (
    <div
      className={`${classes.popOverWrapper} ${overridePopoverWrapper}`}
      ref={popoverRef}
      data-testid={testID}
    >
      <Flex
        align="center"
        justify="between"
        overrideStyle={{
          padding: Theme.padding,
          backgroundColor: disabled ? RetailerJordanColors.DISABLED : "",
        }}
        onClick={disabled ? null : togglePopover}
      >
        <label>
          {
            options.find((item) => item?.[valueKey] === value)?.[
              selectedLabelKey
            ]
          }
        </label>
        <img
          src={process.env.PUBLIC_URL + "/assets/grey-arrow.svg"}
          alt="arrow"
          className={isOpen ? classes.rotate : undefined}
          data-testid="downarrow_icon"
        />
      </Flex>
      {isOpen && (
        <div className={`${classes.popover} ${overridePopover}`}>
          <ul style={{ maxHeight: 150, overflowY: "auto" }}>
            {options.map((option, index) => (
              <li
                key={index}
                className={
                  option?.[valueKey] === value ? classes.disabledClass : ""
                }
                onClick={() => {
                  option?.[valueKey] === value
                    ? null
                    : handleOptionSelect(option);
                }}
                data-testid={`listValue_${index}`}
              >
                {option?.[labelKey]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

PopOver.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  overridePopoverWrapper: PropTypes.string,
  overridePopover: PropTypes.string,
};

export default PopOver;
