import React from "react";
import { createUseStyles } from "react-jss";
import { Flex, Typography } from "Component";
import Theme from "Global/retailerJordanTheme";
import { RetailerJordanColors } from "Global/colors";
import JordanButton from "./jordanButton";

const useStyles = createUseStyles({
  cardContainer: {
    width: 592,
    borderRadius: Theme.borderRadius - 4,
    paddingLeft: Theme.padding * 10,
    paddingRight: Theme.padding * 10,
    boxSizing: "border-box",
    "@media (max-width: 1185px)": {
      width: "100%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
    "@media (max-width: 550px)": {
      width: "100%",
    },
  },
  headingContainerClass: {
    paddingBottom: Theme.padding * 3,
    paddingTop: Theme.padding * 3,
  },
});

const ErrorCard = (props) => {
  const {
    Component = null,
    heading = "",
    subHeading = "",
    buttonText = "Back",
    onPressHandler = null,
    showButton = true,
  } = props;
  const classes = useStyles();
  return (
    <Flex
      direction="column"
      align="center"
      overrideClass={classes.cardContainer}
      testID="errorCardId"
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/error.gif"}
        style={{ width: 150, height: 150 }}
        data-testid="errorCardGif"
      />
      <Flex
        direction="column"
        justify="center"
        align="center"
        overrideClass={classes.headingContainerClass}
      >
        <Typography
          color={RetailerJordanColors.GREY900}
          type="fontLargeSemiBold"
          overrideStyle={{ textAlign: "center" }}
        >
          {heading}
        </Typography>
        <Typography
          color={RetailerJordanColors.GREY600}
          type="fontSmall"
          overrideStyle={{ textAlign: "center", marginTop: Theme.margin / 2 }}
        >
          {subHeading}
        </Typography>
      </Flex>
      <Flex align="end">{Component}</Flex>
      {showButton ? (
        <JordanButton
          msg={buttonText}
          onClickHandler={onPressHandler}
          testID="errorCardButton"
        />
      ) : null}
    </Flex>
  );
};

export default ErrorCard;
