import CryptoJS from "crypto-js";
import { SECRET_PASS } from "./constant";
export const clearCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const EncryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_PASS).toString();
};
export const DecryptData = (data) => {
  if (data !== "" && data !== "null" && data !== null) {
    const decrypted = CryptoJS.AES?.decrypt(data, SECRET_PASS);
    if (decrypted) {
      try {
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      } catch (e) {
        return null;
      }
    }
  }
  return "";
};
export const setFavicon = (logo) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = logo;
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const validateEmail = (value = "", required = true) => {
  if (!required && !value) return "";
  if (!value) return "Please enter email";
  if (value.length < 2 || value.length > 30)
    return "Please keep it between 2-30 characters";
  if (!emailRegex.test(value)) return "Enter a valid email";
  return "";
};

export const isNumber = (value) => {
  return typeof value === "number" && isFinite(value);
};
export const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};
