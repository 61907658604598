import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
const useStyles = createUseStyles((theme) => ({
  viewContainer: {
    // @ts-ignore
    padding: theme?.padding,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
  },
}));
const Card = (props) => {
  const {
    children,
    overrideClass = "",
    overrideStyle = {},
    direction = "row",
    justify = "center",
    align = "center",
  } = props;
  const classes = useStyles();
  return (
    <Flex
      justify={justify}
      align={align}
      direction={direction}
      overrideStyle={overrideStyle}
      overrideClass={`${classes.viewContainer} ${overrideClass}`}
    >
      {children}
    </Flex>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  overrideClass: PropTypes.string,
  overrideStyle: PropTypes.object,
};

export default Card;
