import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { dtutil, validateRegex } from "Utils/helpers";
import Label from "./label";
import Flex from "./flex";
import Typography from "./Typography";
const useStyles = createUseStyles((theme) => ({
  inputStyle: {
    width: "100%",
    // @ts-ignore
    padding: `${theme.padding}px ${theme.padding + 6}px`,
    height: "38px",
    boxSizing: "border-box",
    border: `1px solid ${Colors.BORDERCOLOR}`,
    transition: "0.1s",
    outline: "none",
    // @ts-ignore
    borderRadius: theme.borderRadius,
    color: Colors.FORM_TEXT_COLOR,
    fontFamily: "Poppins-Regular",
    "&:focus": {
      // @ts-ignore
      border: `2px solid ${theme?.primaryColor}`,
    },
  },

  borderRed: {
    border: `1px solid ${Colors.RED}`,
  },
  container: {
    border: "none",
    width: "100%",
    // @ts-ignore
    padding: theme.padding / 2,
  },
  inputBoxContainer: {
    height: "100%",
  },
}));
const InputBox = (props) => {
  const {
    handleChange = null,
    format = "text",
    name = "inputbox",
    error = false,
    errorMsg = "",
    value = "",
    label = "",
    placeholder = "",
    disabled = false,
    required = false,
    inputBoxStyle = {},
    containerStyle = {},
    labelStyle = {},
    containerClass = "",
    inputClass = "",
    regexExp = null,
    helpText = null,
    showLabel = true,
    maxLength = null,
    overrideRef = null,
    leftComponent = null,
    rightComponent = null,
    testID = "inputboxID",
    helpTextColor = Colors.RED,
    errorTextColor = Colors.RED,
    minDate = dtutil(new Date()).format("YYYY-MM-DD"),
    maxDate = null,
    onKeyDownHandler = null,
    type = "text",
    startsWithZero = false,
    overrideInputBoxConatiner = {},
    labelStyleClass = "",
    overrideMainContainer = {},
    textInputLabelColor = "",
    ...extraProps
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [props.value, props.disabled]);
  const onChangeHandler = (e) => {
    let inputData = e.target.value;
    if (
      format === "number" &&
      inputData !== "" &&
      inputData.startsWith("0") &&
      inputData.length > 1 &&
      !startsWithZero
    ) {
      inputData = "1";
    }
    if (maxLength && Math.abs(inputData.length - 1) === maxLength) {
      return;
    }
    if (format === "number") {
      let numbers = /^\d+$/;
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, e);
        return;
      }
      if (!inputData.match(numbers)) {
        handleChange && handleChange(props.value, e);
        return;
      }
    }
    if (format === "decimal") {
      let numbers = /^\d*(\.)?(\d{0,20})?$/;
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, e);
        return;
      }
      if (!inputData.match(numbers)) {
        handleChange && handleChange(props.value, e);
        return;
      }
    }
    if (!regexExp) {
      setInputValue(inputData);
      handleChange && handleChange(inputData, e);
    } else {
      const isValid = validateRegex(inputData, regexExp);
      if (inputData === "") {
        setInputValue(inputData);
        handleChange && handleChange(inputData, { error: false }, e);
        return;
      }
      if (!isValid) {
        handleChange && handleChange(inputValue, { error: true }, e);
        return;
      } else {
        handleChange && handleChange(inputData, { error: false }, e);
        setInputValue(inputData);
      }
    }
  };
  const handleOnKeyDown = (e) => {
    if (onKeyDownHandler) {
      onKeyDownHandler();
    } else if (type === "date") {
      e.preventDefault();
      return false;
    }
  };
  const { container, inputStyle, borderRed, inputBoxContainer } = classes;
  return (
    <div style={{ width: "100%", ...overrideMainContainer }}>
      <Flex
        direction="column"
        overrideStyle={{ ...containerStyle }}
        overrideClass={`${container} ${containerClass}`}
      >
        {showLabel && (
          <Label
            label={label}
            required={required}
            labelStyle={labelStyle}
            overrideLabelClass={labelStyleClass}
            labelColour={textInputLabelColor}
          />
        )}
        <Flex
          justify="center"
          align="center"
          overrideClass={`${inputBoxContainer}`}
          overrideStyle={{ ...overrideInputBoxConatiner }}
        >
          {leftComponent && leftComponent()}
          <input
            type={type}
            name={name}
            className={` ${inputStyle} ${inputClass} ${
              error ? borderRed : ""
            } `}
            value={inputValue}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            onChange={disabled ? null : onChangeHandler}
            style={{ ...inputBoxStyle }}
            data-testid={testID}
            min={minDate}
            max={maxDate}
            onKeyDown={handleOnKeyDown}
            {...extraProps}
            ref={overrideRef}
          />
          {rightComponent && rightComponent()}
        </Flex>
        {error ? (
          <Typography color={errorTextColor}>{errorMsg}</Typography>
        ) : helpText ? (
          <Typography color={helpTextColor}>{helpText}</Typography>
        ) : null}
      </Flex>
    </div>
  );
};

InputBox.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  helpText: PropTypes.string,
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  placeholder: PropTypes.string,
  inputBoxStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  format: PropTypes.string,
  name: PropTypes.string,
  leftComponent: PropTypes.func,
  rightComponent: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  onClick: PropTypes.func,
  maxLength: PropTypes.number,
  handleChange: PropTypes.func,
  regexExp: PropTypes.array,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  testID: PropTypes.string,
  tooltip: PropTypes.bool,
  tooltipMsg: PropTypes.string,
  helpTextColor: PropTypes.string,
  errorTextColor: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  dateHandler: PropTypes.string,
  onKeyDownHandler: PropTypes.func,
  startsWithZero: PropTypes.bool,
  overrideInputBoxConatiner: PropTypes.object,
  labelStyleClass: PropTypes.string,
  overrideMainContainer: PropTypes.object,
  textInputLabelColor: PropTypes.string,
};

export default InputBox;
