import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import Flex from "./flex";
import Label from "./label";
const useStyles = createUseStyles((theme) => ({
  paragraphStyle: {
    // @ts-ignore
    marginLeft: theme.margin * 2,
    fontSize: "20px",
  },
  sliderStyle: {
    height: "38px",
    width: "100%",
  },
}));

const Slider = (props) => {
  const [volume, setVolume] = useState(1);
  const finalVolume = volume ** 2;
  const {
    showLabel = true,
    label = "",
    type = "",
    min = 0,
    max = 1,
    step = "",
    value = volume,
    disabled = false,
    overrideSliderClass = "",
    overrideSliderStyle = {},
    testID = "sliderID",
    ...rest
  } = props;

  const handleChange = (e) => {
    setVolume(e.target.value);
  };

  const classes = useStyles();
  const { paragraphStyle, sliderStyle } = classes;
  return (
    <Flex direction="column">
      {showLabel && <Label label={label} />}
      <input
        type={type}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className={`${sliderStyle} ${overrideSliderClass}`}
        style={overrideSliderStyle}
        disabled={disabled}
        data-testid={testID}
        {...rest}
      />
      <p className={paragraphStyle}>Volume: {finalVolume}</p>
    </Flex>
  );
};

Slider.propTypes = {
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  overrideSliderClass: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  overrideSliderStyle: PropTypes.object,
  testID: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.string,
};

export default Slider;
