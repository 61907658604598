import React from "react";
const ReportDownloadIcon = (props) => {
  const { color = "#29242D" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3811_4599)">
        <path
          d="M15.9999 21.3335L9.33325 14.6668L11.1999 12.7335L14.6666 16.2002V5.3335H17.3333V16.2002L20.7999 12.7335L22.6666 14.6668L15.9999 21.3335ZM7.99992 26.6668C7.26659 26.6668 6.63881 26.4057 6.11659 25.8835C5.59436 25.3613 5.33325 24.7335 5.33325 24.0002V20.0002H7.99992V24.0002H23.9999V20.0002H26.6666V24.0002C26.6666 24.7335 26.4055 25.3613 25.8833 25.8835C25.361 26.4057 24.7333 26.6668 23.9999 26.6668H7.99992Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ReportDownloadIcon;
