import React from "react";
import { Flex, Label, Typography } from "Component";
import DatePicker from "react-datepicker";
import { createUseStyles } from "react-jss";
import { RetailerJordanColors } from "Global/colors";
import Theme from "Global/retailerJordanTheme";
import UITranslator from "Hooks/uiTranslator";
import "react-datepicker/dist/react-datepicker.css";
import { DownAngleArrow, StraightLine } from "RetailerJordan/Assets/Icons";
const useStyles = createUseStyles({
  overrideInputClass: {
    minHeight: 44,
    fontSize: 14,
    borderRadius: 4,
    fontFamily: "PlusJakartaSans-Regular",
    "& ::placeholder": {
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Medium",
      color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
    },
    "&:disabled": {
      background: RetailerJordanColors.DISABLED,
    },
  },
  selectBoxContainerClass: {
    margin: 2,
  },
  overrideOptionClass: {
    padding: "8px 10px",
    "& div": {
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Regular",
      color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
    },
  },
  overrideOptionContClass: {
    borderRadius: "2px",
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.06)",
  },
  directionChange: {
    direction: "rtl",
  },
  rightDateInputClass: {
    width: "94%!important",
  },
  arrowClass: {
    height: 35,
    position: "absolute",
    right: 10,
    top: "50%",
    width: "initial",
    transform: "translateY(-50%)",
    paddingLeft: Theme.padding * 1.5,
    paddingRight: Theme.padding * 1.5,
    "& img": {
      width: Theme.padding * 3,
    },
  },
  rightArrowClass: {
    left: 10,
    right: "initial",
    borderLeft: "none",
    borderRight: `1px solid ${RetailerJordanColors.BORDERCOLOR}`,
  },
  dateInputClass: {
    minHeight: 34,
    fontSize: 14,
    borderRadius: 4,
    fontFamily: "PlusJakartaSans-Regular",
    width: "90%!important",
    border: `1px solid ${RetailerJordanColors.BORDERCOLOR}!important`,
    marginTop: "3px",
    padding: "4px 14px",
    "& ::placeholder": {
      fontSize: 14,
      fontFamily: "PlusJakartaSans-Medium",
      color: RetailerJordanColors.TEXTMEDIUMEMPHASIS,
    },
    "&:focus-visible": {
      outline: `2px solid ${RetailerJordanColors.PRIMARY}!important`,
    },
    "@media (max-width: 767px)": {
      width: "94%!important",
    },
    "&:disabled": {
      background: RetailerJordanColors.DISABLED,
    },
  },
  customDateInput: (props) => ({
    position: "relative",
    "& .react-datepicker__navigation--next": {
      "& span": {
        "&::before": {
          borderColor: `${
            props?.error
              ? RetailerJordanColors.RED
              : RetailerJordanColors.PRIMARY
          }!important`,
        },
      },
    },
    "& .react-datepicker__navigation--previous": {
      "& span": {
        "&::before": {
          borderColor: `${
            props?.error
              ? RetailerJordanColors.RED
              : RetailerJordanColors.PRIMARY
          }!important`,
        },
      },
    },
    "& .react-datepicker__day--selected": {
      backgroundColor: RetailerJordanColors.PRIMARY,
    },
    "& .react-datepicker__day-names": {
      ...Theme.typography.p,
    },
    "& .react-datepicker__month": {
      ...Theme.typography.p,
    },
    "& .react-datepicker__week": {
      ...Theme.typography.p,
    },
  }),

  arrowStyle: {
    width: "13px",
    height: "13px",
    marginRight: (props) => (props.isMulti ? "" : `${Theme.margin * 2}%`),
    marginLeft: (props) => (props.isMulti ? `${Theme.margin * 2}%` : ""),
    opacity: (props) => (props?.disabled ? 0.3 : 0.5),
    "&:hover": {
      opacity: (props) => (props?.disabled ? 0.3 : 1),
    },
  },
  datePickerContClass: {
    padding: Theme.padding / 2,
  },
});
const DatePickerComponent = (props) => {
  const {
    showLabel = true,
    label = "",
    error = false,
    errorMsg = "",
    testID = "dataPicker_ID",
    datePickerComponentContClass = "",
    ...rest
  } = props;
  const classes = useStyles({ ...props, error });
  const lang = UITranslator();
  return (
    <Flex
      direction="column"
      // overrideStyle={{
      //   padding: Theme.padding / 2,
      // }}
      data-testid={testID}
      overrideClass={`${classes.datePickerContClass} ${datePickerComponentContClass}`}
    >
      {showLabel && <Label label={label} />}
      <Flex direction="column" overrideClass={classes.customDateInput}>
        <DatePicker
          className={`${classes.dateInputClass} ${
            lang && classes.rightDateInputClass
          }`}
          {...rest}
        />
        <Flex
          overrideClass={`${classes.arrowClass} ${
            lang && classes.rightArrowClass
          }`}
        >
          <StraightLine
            style={{
              height: "100%",
              left: Theme?.margin * 2,
            }}
            id="s1"
          />
          <DownAngleArrow
            style={{ alignSelf: "center" }}
            className={`${classes.arrowStyle}`}
            id="s1"
          />
        </Flex>
      </Flex>
      {error ? (
        <Typography color={RetailerJordanColors.RED}>{errorMsg}</Typography>
      ) : null}
    </Flex>
  );
};

export default DatePickerComponent;
