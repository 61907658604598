import React, { useState } from "react";
import Flex from "./flex";
import Typography from "./Typography";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Colors from "Global/colors";
import { getPathData } from "Utils/helpers";
import { CloseIcon } from "OldRetailer/Assets/Icons";
const colorsData = {
  error: Colors.RED,
  warning: Colors.BLACK,
  information: Colors.BLACK,
  success: Colors.GREEN_30,
};
const useStyles = createUseStyles((theme) => ({
  typeErr: {
    borderLeft: `5px solid ${Colors.RED}`,
    backgroundColor: Colors.ORANGE_20,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    boxSizing: "border-box",
    // @ts-ignore
    padding: theme?.padding,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  typeWarn: {
    borderLeft: `5px solid ${Colors.BROWN}`,
    backgroundColor: Colors.ORANGE,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    boxSizing: "border-box",
    // @ts-ignore
    padding: theme?.padding,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  typeInfo: {
    backgroundColor: Colors.YELLOW_20,
    borderLeft: `5px solid ${Colors.YELLOW}`,
    // @ts-ignore
    borderRadius: theme?.borderRadius,
    boxSizing: "border-box",
    // @ts-ignore
    padding: theme?.padding,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  typeSuccess: {
    boxSizing: "border-box",
    backgroundColor: Colors.GREEN_20,
    borderLeft: `5px solid ${Colors.GREEN_40}`,
    // @ts-ignore
    borderRadius: theme.borderRadius,
    // @ts-ignore
    padding: theme?.padding,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
  },
  imgStyle: {
    // @ts-ignore
    cursor: "pointer",
    float: "right",
    backgroundcolor: "transparent",
    heigth: "15px",
    width: "15px",
  },
}));

const Notification = (props) => {
  const {
    children,
    notifyType = "error",
    overrideLabelStyle = {},
    overrideLabelClass = "",
    showClose = false,
    overrideContainerStyle = {},
    overrideContainerClass = "",
    overrideCloseClass = "",
    overrideCloseStyle = {},
    onClose = null,
    testID = "notifyId",
  } = props;
  const classes = useStyles({ ...props });
  const { typeErr, typeWarn, typeInfo, typeSuccess, imgStyle } = classes;
  const [show, setShow] = useState(true);
  const styledObj = {
    error: typeErr,
    warning: typeWarn,
    information: typeInfo,
    success: typeSuccess,
  };
  const onCloseHandler = () => {
    setShow(false);
    if (onClose) onClose();
  };
  if (!show) {
    return null;
  }
  const notificationType = getPathData(styledObj, [notifyType], null);
  const colorType = getPathData(colorsData, [notifyType], Colors.BLACK);
  return (
    <Flex
      direction="row"
      justify="center"
      align="center"
      overrideClass={overrideContainerClass}
      overrideStyle={overrideContainerStyle}
    >
      <Typography
        type="notify"
        overrideClass={`${
          notificationType ? notificationType : ""
        } ${overrideLabelClass}`}
        // @ts-ignore
        style={overrideLabelStyle}
        data-testid={testID}
        color={colorType}
      >
        {children}
        {showClose && (
          <CloseIcon
            onClick={onCloseHandler}
            className={`${imgStyle} ${overrideCloseClass}`}
            style={overrideCloseStyle}
            data-testid={"notifyclose-test"}
          />
        )}
      </Typography>
    </Flex>
  );
};

Notification.propTypes = {
  overrideStyle: PropTypes.object,
  overrideContainerStyle: PropTypes.object,
  overrideLabelStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  notifyType: PropTypes.oneOf(["information", "warning", "error", "success"]),
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  classname: PropTypes.string,
  overrideContainerClass: PropTypes.string,
  overrideLabelClass: PropTypes.string,
  testID: PropTypes.string,
};

export default Notification;
